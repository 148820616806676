<template>
  <div >
    <!--Banner
    <section>
      <div class="topBanner"></div>
    </section>-->

    <!--Intro-->
    <section class="gridpad">
      <v-row>
        <v-col cols="12" class=" ">
          <div class="introBox">
            <!--<h1 class="introTitle">{{ $t('intro.title') }}</h1>-->
            <p class="txtAlign mt-5" v-html="$t('intro.txt')"></p>
          </div>
        </v-col>
      </v-row>

    </section>

    <!--SubmersiblePump-->
    <section id="submersible" class="v-tierce2">
      <v-row class="">
        <v-col class="pa-0 d-flex align-center justify-center minBoxImg">
          <div class="subImg"></div>
        </v-col>
        <v-col class="txt-j minBoxImg">
          <div class="padPump">
            <h2 class="subTitle mb-2">{{ $t('submersiblePump.title') }}</h2>
            <p v-html="$t('submersiblePump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('submersiblePump.ol1')"></li>
              <li v-html="$t('submersiblePump.ol2')"></li>
              <li v-html="$t('submersiblePump.ol3')"></li>
              <li v-html="$t('submersiblePump.ol4')"></li>
              <li v-html="$t('submersiblePump.ol5')"></li>
              <li v-html="$t('submersiblePump.ol6')"></li>
            </ol>
            <p v-html="$t('submersiblePump.bottomTxt')"></p>
            <router-link class="button mt-4 btn-c" to="/contact"><p class="buttonText" v-html="$t('submersiblePump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--Égout-->
    <section id="sewer" class="">
      <v-row class="">
        <v-col cols="12" lg="6" class="txt-j">
          <div class="padPump">
            <h2 class="sewerTitle mb-2">{{ $t('sewerPump.title') }}</h2>
            <p v-html="$t('sewerPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('sewerPump.ol1')"></li>
              <li v-html="$t('sewerPump.ol2')"></li>
              <li v-html="$t('sewerPump.ol3')"></li>
              <li v-html="$t('sewerPump.ol4')"></li>
              <li v-html="$t('sewerPump.ol5')"></li>
              <li v-html="$t('sewerPump.ol6')"></li>
            </ol>
            <p v-html="$t('sewerPump.bottomTxt')"></p>
            <router-link class="button mt-4 btn-c" to="/contact"><p class="buttonText" v-html="$t('sewerPump.btn')"></p></router-link>
          </div>
        </v-col>
        <v-col cols="12" lg="6" class="pa-0 d-flex align-center justify-center flex-end">
          <div style="float:right" class="sewerImg"></div>
        </v-col> 
      </v-row>
    </section>
    <!--Puisard-->
    <section id="Sump" >
      <v-row class="v-primary-light-1">
        <v-col cols="12" lg="8" class="d-flex justify-center align-center section-c">
          <div class="padPump">
            <h2 class="SumpPumpTitle mb-2">{{ $t('sumpPump.title') }}</h2>
            <p v-html="$t('sumpPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('sumpPump.ol1')"></li>
              <li v-html="$t('sumpPump.ol2')"></li>
              <li v-html="$t('sumpPump.ol3')"></li>
              <li v-html="$t('sumpPump.ol4')"></li>
            </ol>
            <p v-html="$t('sumpPump.subtxt')"></p>
            <ol class="olPad">
              <li v-html="$t('sumpPump.ol5')"></li>
              <li v-html="$t('sumpPump.ol6')"></li>
              <li v-html="$t('sumpPump.ol7')"></li>
              <li v-html="$t('sumpPump.ol8')"></li>
            </ol>
            <p v-html="$t('sumpPump.subtxt2')"></p>
            <ul class="olPad">
              <li v-html="$t('sumpPump.ol9')"></li>
              <li v-html="$t('sumpPump.ol10')"></li>
              <li v-html="$t('sumpPump.ol11')"></li>
              <li v-html="$t('sumpPump.ol12')"></li>
              <li v-html="$t('sumpPump.ol13')"></li>
            </ul>
            <p v-html="$t('sumpPump.bottomTxt')"></p>
            <router-link class="button mt-4 btn-c" to="/contact" ><p class="buttonText" v-html="$t('otherPump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
      <v-row class="pb-16 v-tierce2">
        <v-col cols="12" class="pa-0 d-flex justify-center">
          <v-col v-for="(item, i) in puisardPumps" :key="i" class="">
            <v-container class="pumpItem pa-">          
              <img style="border: 10px solid rgb(var(--secondary)); box-shadow: 0px 0px 10px black;" class="pumpImg" :src="item.img"/>
            </v-container>
          </v-col>
        </v-col>
      </v-row>
    </section>
    <!--Piston-->
    <section id="piston" class="">
      <v-row class="">
        <v-col class="d-flex justify-center align-center minBoxImg">
          <div class="padPump">
            <h2 class="pistonTitle mb-2">{{ $t('pistonPump.title') }}</h2>
            <p v-html="$t('pistonPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('pistonPump.ol1')"></li>
              <li v-html="$t('pistonPump.ol2')"></li>
              <li v-html="$t('pistonPump.ol3')"></li>
              <li v-html="$t('pistonPump.ol4')"></li>
            </ol>
            <p v-html="$t('pistonPump.subtxt')"></p>
            <ol class="olPad">
              <li v-html="$t('pistonPump.ol5')"></li>
              <li v-html="$t('pistonPump.ol6')"></li>
              <li v-html="$t('pistonPump.ol7')"></li>
              <li v-html="$t('pistonPump.ol8')"></li>
              <li v-html="$t('pistonPump.ol9')"></li>
            </ol>
            <p v-html="$t('pistonPump.bottomTxt')"></p>
          </div>
        </v-col>
        <v-col class="pa-0 d-flex align-center justify-center minBoxImg">
          <div class="pistonImg"></div>
        </v-col>
      </v-row>
    </section>
    <!--Section4 ContactUs-->
    <contactUs/>
        <!--prominent-->
    <section id="prominent" class="">
      <v-row class="">
        <v-col cols="12" lg="6" class="pa-0 d-flex align-center justify-center flex-end">
          <div class="prominentImg"></div>
        </v-col> 
        <v-col cols="12" lg="6" class="txt-j">
          <div class="padPump">
            <h2 class="prominentTitle mb-2">{{ $t('prominentPump.title') }}</h2>
            <p v-html="$t('prominentPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('prominentPump.ol1')"></li>
              <li v-html="$t('prominentPump.ol2')"></li>
              <li v-html="$t('prominentPump.ol3')"></li>
              <li v-html="$t('prominentPump.ol4')"></li>
              <li v-html="$t('prominentPump.ol5')"></li>
              <li v-html="$t('prominentPump.ol6')"></li>
              <li v-html="$t('prominentPump.ol7')"></li>
            </ol>
            <p v-html="$t('prominentPump.bottomTxt')"></p>
            <router-link class="button mt-4 btn-c" to="/contact"><p class="buttonText" v-html="$t('sewerPump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--sécurité-->
    <section id="security" class="v-tierce2">
      <v-row class="">
        <v-col cols="12" lg="8" class="txt-j section-c">
          <div class="padPump">
            <h2 class="securityTitle mb-2">{{ $t('securityPump.title') }}</h2>
            <p v-html="$t('securityPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('securityPump.ol1')"></li>
              <li v-html="$t('securityPump.ol2')"></li>
              <li v-html="$t('securityPump.ol3')"></li>
              <li v-html="$t('securityPump.ol4')"></li>
              <li v-html="$t('securityPump.ol5')"></li>
              <li v-html="$t('securityPump.ol6')"></li>
              <li v-html="$t('securityPump.ol7')"></li>
            </ol>
            <p v-html="$t('securityPump.bottomTxt')"></p>
            <router-link class="button mt-4 btn-c" to="/contact"><p class="buttonText" v-html="$t('securityPump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
      <v-row class="pb-16 v-tierce2">
        <v-col cols="12" class="pa-0 d-flex justify-center">
          <v-col v-for="(item, i) in securityPumps" :key="i" class="">
            <v-container class="pumpItem pa-">          
              <img style="border: 10px solid rgb(var(--secondary)); box-shadow: 0px 0px 10px black;" class="pumpImg" :src="item.img"/>
            </v-container>
          </v-col>
        </v-col>
      </v-row>
      <!--
      <p class="error txt-c">image pompe à fournir !</p>
      <v-row class="pb-16">       
        <v-col cols="12" class="pa-0 d-flex justify-center">         
          <v-col v-for="(item, i) in otherPumps" :key="i" class="">
            <v-container class="pumpItem pa-">          
              <img class="pumpImg" :src="item.img"/>
            </v-container>
          </v-col>
        </v-col>
      </v-row>-->
    </section>
        <!--circulation chauffage-->
    <section id="traffic" class="">
      <v-row class="">
        <v-col class="d-flex justify-center align-center minBoxImg">
          <div class="padPump">
            <h2 class="trafficTitle mb-2">{{ $t('trafficPump.title') }}</h2>
            <p v-html="$t('trafficPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('trafficPump.ol1')"></li>
              <li v-html="$t('trafficPump.ol2')"></li>
              <li v-html="$t('trafficPump.ol3')"></li>
              <li v-html="$t('trafficPump.ol4')"></li>
              <li v-html="$t('trafficPump.ol5')"></li>
              <li v-html="$t('trafficPump.ol6')"></li>
              <li v-html="$t('trafficPump.ol7')"></li>
            </ol>
            <p v-html="$t('trafficPump.bottomTxt')"></p>
          </div>
        </v-col>
        <v-col class="pa-0 d-flex align-center justify-center minBoxImg">
          <div class="trafficImg"></div>
        </v-col>
      </v-row>
    </section>
    <!--Section4 ContactUs-->
    <contactUs/>
        <!--diaphragme-->
    <section id="diaphragm" >
      <v-row class="">
        <v-col cols="12" lg="6" class="pa-0 d-flex align-center justify-center">
          <div class="diaphragmImg"></div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j">
          <div class="padPump">
            <h2 class="diaphragmTitle mb-2">{{ $t('diaphragmPump.title') }}</h2>
            <p v-html="$t('diaphragmPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('diaphragmPump.ol1')"></li>
              <li v-html="$t('diaphragmPump.ol2')"></li>
              <li v-html="$t('diaphragmPump.ol3')"></li>
              <li v-html="$t('diaphragmPump.ol4')"></li>
            </ol>
            <p v-html="$t('diaphragmPump.subtxt')"></p>
            <ol class="olPad">
              <li v-html="$t('diaphragmPump.ol5')"></li>
              <li v-html="$t('diaphragmPump.ol6')"></li>
              <li v-html="$t('diaphragmPump.ol7')"></li>
              <li v-html="$t('diaphragmPump.ol8')"></li>
              <li v-html="$t('diaphragmPump.ol9')"></li>
              <li v-html="$t('diaphragmPump.ol10')"></li>
            </ol>
            <p v-html="$t('diaphragmPump.bottomTxt')"></p>
            <router-link class="button mt-4 btn-c" to="/contact"><p class="buttonText" v-html="$t('diaphragmPump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
    </section>
            <!--transfert-->
    <section id="transfer" class="v-primary-light-1">
      <v-row >
        <v-col cols="12" lg="8" class="txt-j section-c">
          <div class="padPump">
            <h2 class="transferTitle mb-2">{{ $t('transferPump.title') }}</h2>
            <p v-html="$t('transferPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('transferPump.ol1')"></li>
              <li v-html="$t('transferPump.ol2')"></li>
              <li v-html="$t('transferPump.ol3')"></li>
              <li v-html="$t('transferPump.ol4')"></li>
              <li v-html="$t('transferPump.ol5')"></li>
              <li v-html="$t('transferPump.ol6')"></li>
              <li v-html="$t('transferPump.ol7')"></li>
            </ol>
            <p v-html="$t('transferPump.bottomTxt')"></p>
            <router-link class="button mt-4 btn-c" to="/contact"><p class="buttonText" v-html="$t('transferPump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
      <v-row class="pb-16">       
        <v-col cols="12" class="pa-0 d-flex justify-center">         
          <v-col v-for="(item, i) in transfertPumps" :key="i" class="">
            <v-container class="pumpItem pa-">          
              <img style="border: 10px solid rgb(var(--secondary)); box-shadow: 0px 0px 10px black;" class="pumpImg" :src="item.img"/>
            </v-container>
          </v-col>
        </v-col>
      </v-row>
    </section>
            <!--piscine-->
    <section id="pool" class="">
      <v-row class="">
        <v-col cols="12" lg="6" class="pa-0 d-flex align-center justify-center">
          <div class="poolImg"></div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j">
          <div class="padPump">
            <h2 class="poolTitle mb-2">{{ $t('poolPump.title') }}</h2>
            <p v-html="$t('poolPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('poolPump.ol1')"></li>
              <li v-html="$t('poolPump.ol2')"></li>
              <li v-html="$t('poolPump.ol3')"></li>
              <li v-html="$t('poolPump.ol4')"></li>
            </ol>
            <p v-html="$t('poolPump.subtxt')"></p>
            <ol class="olPad">
              <li v-html="$t('poolPump.ol5')"></li>
              <li v-html="$t('poolPump.ol6')"></li>
              <li v-html="$t('poolPump.ol7')"></li>
              <li v-html="$t('poolPump.ol8')"></li>
              <li v-html="$t('poolPump.ol9')"></li>
              <li v-html="$t('poolPump.ol10')"></li>
              <li v-html="$t('poolPump.ol11')"></li>
            </ol>
            <p v-html="$t('poolPump.bottomTxt')"></p>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--Section4 ContactUs-->
    <contactUs/>
            <!--spa-->
    <section id="spa" class="">
      <v-row class="">
        <v-col cols="12" lg="6" class="d-flex justify-center align-center">
          <div class="padPump">
            <h2 class="spaPumpTitle mb-2">{{ $t('spaPump.title') }}</h2>
            <p v-html="$t('spaPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('spaPump.ol1')"></li>
              <li v-html="$t('spaPump.ol2')"></li>
              <li v-html="$t('spaPump.ol3')"></li>
              <li v-html="$t('spaPump.ol4')"></li>
            </ol>
            <p v-html="$t('spaPump.subtxt')"></p>
            <ol class="olPad">
              <li v-html="$t('spaPump.ol5')"></li>
              <li v-html="$t('spaPump.ol6')"></li>
              <li v-html="$t('spaPump.ol7')"></li>
              <li v-html="$t('spaPump.ol8')"></li>
              <li v-html="$t('spaPump.ol9')"></li>
            </ol>
            <p v-html="$t('spaPump.bottomTxt')"></p>
            <router-link class="button mt-4 btn-c" to="/contact" ><p class="buttonText" v-html="$t('spaPump.btn')"></p></router-link>
          </div>
        </v-col>
        <v-col cols="12" lg="6" class="pa-0 d-flex align-center justify-center">
          <div class="spaPumpImg"></div>
        </v-col>
      </v-row>
    </section>
                <!--irrigation-->
    <section id="irrigation" class="v-tierce2">
      <v-row >
        <v-col cols="12" lg="8" class="txt-j section-c">
          <div class="padPump">
            <h2 class="irrigationTitle mb-2">{{ $t('irrigationPump.title') }}</h2>
            <p v-html="$t('irrigationPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('irrigationPump.ol1')"></li>
              <li v-html="$t('irrigationPump.ol2')"></li>
              <li v-html="$t('irrigationPump.ol3')"></li>
            </ol>
            <p v-html="$t('irrigationPump.bottomTxt')"></p>
            <router-link class="button mt-4 btn-c" to="/contact"><p class="buttonText" v-html="$t('irrigationPump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
      <v-row class="pb-16">       
        <v-col cols="12" class="pa-0 d-flex justify-center">         
          <v-col v-for="(item, i) in irrigationPumps" :key="i" class="">
            <v-container class="pumpItem pa-">          
              <img style="border: 10px solid rgb(var(--secondary)); box-shadow: 0px 0px 10px black;" class="pumpImg" :src="item.img"/>
            </v-container>
          </v-col>
        </v-col>
      </v-row>
    </section>
            <!--booster pompe-->
    <section id="booster" class="">
      <v-row class="">
        <v-col cols="12" lg="6" class="txt-j">
          <div class="padPump">
            <h2 class="sewerTitle mb-2">{{ $t('boosterPump.title') }}</h2>
            <p v-html="$t('boosterPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('boosterPump.ol1')"></li>
              <li v-html="$t('boosterPump.ol2')"></li>
              <li v-html="$t('boosterPump.ol3')"></li>
              <li v-html="$t('boosterPump.ol4')"></li>
              <li v-html="$t('boosterPump.ol5')"></li>
              <li v-html="$t('boosterPump.ol6')"></li>
            </ol>
            <p v-html="$t('boosterPump.bottomTxt')"></p>
          </div>
        </v-col>
        <v-col cols="12" lg="6" class="pa-0 d-flex align-center justify-center flex-end">
          <div style="float:right" class="boosterImg"></div>
        </v-col> 
      </v-row>
    </section>
    <!--Section4 ContactUs-->
    <contactUs/>
    <!--pompe à main-->
    <section id="handPump" class="">
      <v-row class="">
        <v-col cols="12" lg="6" class="pa-0 d-flex align-center justify-center">
          <div class="handPumpImg"></div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j">
          <div class="padPump">
            <h2 class="handPumpTitle mb-2">{{ $t('handPump.title') }}</h2>
            <p v-html="$t('handPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('handPump.ol1')"></li>
              <li v-html="$t('handPump.ol2')"></li>
              <ol type="a" class="olPad">
                <li v-html="$t('handPump.ol2a')"></li>
                <li v-html="$t('handPump.ol2b')"></li>
                <li v-html="$t('handPump.ol2c')"></li>
                <li v-html="$t('handPump.ol2d')"></li>
              </ol>
              <li v-html="$t('handPump.ol3')"></li>
              <li v-html="$t('handPump.ol4')"></li>
              <li v-html="$t('handPump.ol5')"></li>
              <li v-html="$t('handPump.ol6')"></li>
            </ol>
            <p v-html="$t('handPump.bottomTxt')"></p>
            <router-link class="button mt-4 btn-c" to="/contact"><p class="buttonText" v-html="$t('handPump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
    </section>

            <!--turbine-->
    <section id="turbine" class="v-primary-light-1">
      <v-row >
        <v-col cols="12" lg="8" class="txt-j section-c">
          <div class="padPump">
            <h2 class="turbineTitle mb-2">{{ $t('turbinePump.title') }}</h2>
            <p v-html="$t('turbinePump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('turbinePump.ol1')"></li>
              <li v-html="$t('turbinePump.ol2')"></li>
              <li v-html="$t('turbinePump.ol3')"></li>
              <li v-html="$t('turbinePump.ol4')"></li>
            </ol>
            <p v-html="$t('turbinePump.bottomTxt')"></p>
            <router-link class="button mt-4 btn-c" to="/contact"><p class="buttonText" v-html="$t('turbinePump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
      <v-row class="pb-16">       
        <v-col cols="12" class="pa-0 d-flex justify-center">         
          <v-col v-for="(item, i) in turbinePumps" :key="i" class="">
            <v-container class="pumpItem pa-">          
              <img style="border: 10px solid rgb(var(--secondary)); box-shadow: 0px 0px 10px black;" class="pumpImg" :src="item.img"/>
            </v-container>
          </v-col>
        </v-col>
      </v-row>
    </section>
    <!--wellPump
    <section id="wellPump" class="">
      <v-row class="">
        <v-col cols="12" lg="6" class="pa-0 d-flex align-center justify-center">
          <div class="wellPumpImg"></div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j">
          <div class="padPump">
            <h2 class="wellPumpTitle mb-2">{{ $t('wellPump.title') }}</h2>
            <p v-html="$t('wellPump.txt')"></p>
            <ol class="olPad">
              <li v-html="$t('wellPump.ol1')"></li>
              <li v-html="$t('wellPump.ol2')"></li>
              <li v-html="$t('wellPump.ol3')"></li>
              <li v-html="$t('wellPump.ol4')"></li>
              <li v-html="$t('wellPump.ol5')"></li>
            </ol>
            <p v-html="$t('wellPump.bottomTxt')"></p>
            <router-link class="button mt-4 btn-c" to="/contact"><p class="buttonText" v-html="$t('wellPump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
    </section>-->
    
   <!--Distribution-->
   <distributors/>

  </div>
</template>

<style scoped>
  .minBoxImg { min-width: 525px; }
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .subtitle {line-height: 30px;}
  .gridpad {padding-bottom: 50px;}
  .gridpad2 {padding: 30px 100px 30px 100px;}
  .gridpad3 {padding: 0px 100px 0px 100px;}
  .topBanner {height: 450px; background-image: url('../../public/Resources/images/Banner/bannerResidentialPump.jpg'); background-position: center; background-position-y: 100%; background-repeat: no-repeat; background-size: cover;}
  .icon {width: 50px; height: 50px; margin-bottom: -10px;}
  .txtAlign {text-align:center;}
  .hideController { visibility: hidden; height:0px;}
  .hideMController { visibility: visible;}
  .olPad { padding: 0px 40px; }
  /*#region Intro*/ 
  .introBox { padding: 44px 0px 32px 0px;}
  .introTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}
  /*#endregion*/ 
  /*#region SubmersiblePump*/
  
  #submersible ol li {padding: 10px 0px;}

  .subImg {
    height:650px; width:100%; 
    background-image: url("../../public/Resources/images/Products/summersiblePump.jpg");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;}
  .subBox { padding: 44px 0px 32px 0px;}
  .subPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .subTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/ 
    /*#region SewerPump*/
  
    #sewer ol li {padding: 10px 0px;}
  .sewerImg {
    height:500px; 
    width:50%; 
    background-image: url("../../public/Resources/images/Pumps/pompeEgout.jpg");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;
    border: 15px solid rgb(var(--secondary));
    transform: rotate(10deg);
    box-shadow: 0px 0px 20px black;
  }
  .sewerBox { padding: 44px 0px 32px 0px;}
  .sewerPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .sewerTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/
  /*#region pistonPump*/
  
  #piston ol li {padding: 10px 0px;}
  #piston ul li {padding: 10px 0px;}
  .pistonImg {
    height:650px; width:50%; 
    background-image: url("../../public/Resources/images/Pumps/pompePiston.jpg");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;}
  .pistonBox { padding: 44px 0px 32px 0px;}
  .pistonPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .pistonTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/

/*#region SumpPump*/
  
  #well ol li {padding: 10px 0px;}
  .otherImg {
    margin: -90px 0px 0px 0px;
    height:635px; width:478px; 
    background-image: url("../../public/Resources/images/Products/Control-Pump-waterite-Gray1a.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .SumpPumpBox { padding: 44px 0px 32px 0px;}
  .SumpPumpPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .SumpPumpTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/

  /*#region prominentPump*/
  
  #prominent ol li {padding: 10px 0px;}
  .prominentImg {
    height:500px; 
    width:50%; 
    background-image: url("../../public/Resources/images/Pumps/pompeProminent.jpg");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;
    border: 15px solid rgb(var(--secondary));
    transform: rotate(10deg);
    box-shadow: 0px 0px 20px black;
  }
  .prominentBox { padding: 44px 0px 32px 0px;}
  .prominentPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .prominentTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/
    /*#region securityPump*/
  
    #security ol li {padding: 10px 0px;}
  .securityImg {
    height:650px; 
    width:75%; 
    background-image: url("../../public/Resources/images/Products/FindImg.png");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;
  }
  .securityBox { padding: 44px 0px 32px 0px;}
  .securityPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .securityTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/
  /*#region trafficPump*/
  
    #traffic ol li {padding: 10px 0px;}
  .trafficImg {
    height:500px; width:75%; 
    background-image: url("../../public/Resources/images/Products/armstrong.jpg");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;
    border: 15px solid rgb(var(--secondary));
    transform: rotate(10deg);
    box-shadow: 0px 0px 20px black;
  }
  .trafficBox { padding: 44px 0px 32px 0px;}
  .trafficPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .trafficTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/
  /*#region diaphragmPump*/
  
    #diaphragm ol li {padding: 10px 0px;}
  .diaphragmImg {
    height:650px; width:50%; 
    background-image: url("../../public/Resources/images/Pumps/pompeDiaphragme.jpg");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;}
  .diaphragmBox { padding: 44px 0px 32px 0px;}
  .diaphragmPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .diaphragmTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/ 
  
  /*#region transferPump*/
  
      #transfer ol li {padding: 10px 0px;}
  .transferImg {
    height:650px; 
    width:75%; 
    background-image: url("../../public/Resources/images/Products/FindImg.png");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;
  }
  .transferBox { padding: 44px 0px 32px 0px;}
  .transferPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .transferTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/
  
  /*#region poolPump*/
  
    #pool ol li {padding: 10px 0px;}
  .poolImg {
    height:650px; width:50%; 
    background-image: url("../../public/Resources/images/Pumps/pompePiscine.jpg");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;}
  .poolBox { padding: 44px 0px 32px 0px;}
  .poolPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .poolTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/ 
  
  /*#region spaPump*/
  
    #spa ol li {padding: 10px 0px;}
  .spaPumpImg {
    height:500px; width:50%; 
    background-image: url("../../public/Resources/images/Pumps/pompeSpa.jpg");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;
    border: 15px solid rgb(var(--secondary));
    transform: rotate(10deg);
    box-shadow: 0px 0px 20px black;
  }
  .spaPumpBox { padding: 44px 0px 32px 0px;}
  .spaPumpPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .spaPumpTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/
  
  /*#region irrigationPump*/
  
  #irrigation ol li {padding: 10px 0px;}
  .irrigationImg {
    height:650px; width:75%; 
    background-image: url("../../public/Resources/images/Products/FindImg.png");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;
    border: 15px solid rgb(var(--secondary));
    transform: rotate(10deg);
    box-shadow: 0px 0px 20px black;
  }
  .irrigationBox { padding: 44px 0px 32px 0px;}
  .irrigationPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .irrigationTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/

  /*#region handPump*/
  
  #handPump ol li {padding: 10px 0px;}
  .handPumpImg {
    height:650px; width:50%; 
    background-image: url("../../public/Resources/images/Pumps/pompeMain.jpg");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;
  }
  .handPumpBox { padding: 44px 0px 32px 0px;}
  .handPumpPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .handPumpTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/

  /*#region turbinePump*/
    #turbine ol li {padding: 10px 0px;}
  .turbineImg {
    height:650px; width:75%; 
    background-image: url("../../public/Resources/images/Products/FindImg.png");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;
    border: 15px solid rgb(var(--secondary));
    transform: rotate(10deg);
    box-shadow: 0px 0px 20px black;
  }
  .turbineBox { padding: 44px 0px 32px 0px;}
  .turbinePad {text-align: justify; padding: 15px 50px 15px 50px;}
  .turbineTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px; text-align: center;}
  /*#endregion*/

    /*#region boosterPump*/
  
    #booster ol li {padding: 10px 0px;}
  .boosterImg {
    height:300px; 
    width:50%; 
    background-image: url("../../public/Resources/images/Products/boosterPump.jpg");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;
    border: 15px solid rgb(var(--secondary));
    transform: rotate(10deg);
    box-shadow: 0px 0px 20px black;
  }
  .boosterBox { padding: 44px 0px 32px 0px;}
  .boosterPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .boosterTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/

    /*#region wellPump*/
  
    #wellPump ol li {padding: 10px 0px;}
  .wellPumpImg {
    height:500px; width:60%; 
    background-image: url("../../public/Resources/images/Pumps/pompePuit.jpg");
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size:contain;
    border: 15px solid rgb(var(--secondary));
    transform: rotate(10deg);
    box-shadow: 0px 0px 20px black;
  }
  .wellPumpBox { padding: 44px 0px 32px 0px;}
  .wellPumpPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .wellPumpTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/
  /*#region otherPump*/ 

  .boxOtherImg {height: 390px; overflow: hidden;}
  .otherBox { padding: 44px 0px 32px 0px;}
  .otherPad {text-align: justify; padding: 0px 100px 0px 0px;}
  .otherTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .pumpItem {
    height: 170px;  width: 200px; 
    position: relative; display: block; 
    margin: auto auto !important;}
  .pumpImg {height: 170px;  width: 200px; }
  /*#endregion*/ 
  
  
  .padPump {text-align: justify; padding: 15px 50px 15px 50px;}

  @media handheld, only screen and (max-width: 600px) {
    .controllerImg1 {
      height:180px; width:100%; 
      background-size:100%;
    }
    .pumpItem { height: unset; }
    .spaPumpImg { height:250px; }
    .pumpImg {height: 170px;  width: 100%; }
    .poolImg { height:250px;}

    .handPumpPad {padding: 15px;}
    .handPumpImg { height:250px; }
    .turbinePad {padding: 15px;}
    .wellPumpPad {padding: 15px;}
    .wellPumpImg { height:250px; }

    .padPump {padding: 15px;}
    .pistonImg { height:250px; }
    .prominentImg { height:250px; }
    .trafficImg {height: 250px;}
    .diaphragmImg { height:250px; }
    .minBoxImg { min-width: 100%; margin-left: auto; margin-right: auto; display: block;}
  }



  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 0px 25px 50px 25px;}
    .gridpad2 {padding: 0px 25px 60px 25px;}
    .gridpad3 {padding: 0px 25px 30px 25px;}
    .sewerImg { height:250px;}

    .otherPad {padding: 0px 15px 0px 15px !important;}
    .txtAlign {text-align:justify;}
    .resPumpModelPad { margin: 0px; padding: 0px 0px 0px 0px;}
    .otherImg {
      margin: -45px 0px 0px 0px;
      height:350px; width:229px; 
      background-position: center; 
      background-repeat: no-repeat; 
      background-size:cover;
    }
    .olPad { padding: 0px 15px; }
    .boxOtherImg {height: 225px; overflow: hidden;}

  }

  @media handheld, only screen and (max-width: 1279px) {
    .sewerImg {transform: rotate(0deg);}
    .prominentImg {transform: rotate(0deg);}
    .trafficImg {transform: rotate(0deg);}
    .spaPumpImg {transform: rotate(0deg);}
    .wellPumpImg {transform: rotate(0deg);}
    .boosterImg {transform: rotate(0deg);}
    .introBox {padding: 44px 0px 0px 0px;}
    .subImg {
      height:350px; width:75%; 
      background-position: center; 
      background-position-y: 95%;}
    .resImg {height:250px; width:100%; background-position: bottom; background-size:100%;}
    .resPump1 {height:128px; width:169px; }
    .resPump2 {height:128px; width:169px; }
    .resPump3 {height:128px; width:169px; }
    .resPad {padding: 0px 0px;}
    .controllerImg2 {height:150px; width:150px;}
    .hideController { visibility: visible; height: 150px; padding-top: 0px; margin-top:-25px}
    .hideMController { visibility: hidden; height: 0px ;}
    .controllerPad {text-align: justify; padding: 0px 0px 0px 0px}
  }
  @media handheld, only screen and (max-width: 1378px) {
  }
</style>

<script>
    import contactUs from '@/components/bannerContactUs.vue';
    import pageTranslations from '../../public/Resources/i18N/waterPump.json';
    import distributors from '../components/distributors.vue';
    import pumps from '../components/pumps.vue';

    export default {
    name: 'artesianWells',
    data: () => ({
      otherPumps: [
      {
          img:'Resources/images/Products/Sumppump.jpg'
        },
        {
          img:'Resources/images/Products/eau_Pump3885-residentiel-Goudspumps.jpg'
        },
        {
          img:'Resources/images/Products/eau_Pump3886-residentiel-Goudspumps.jpg'
        },
        {
          img:'Resources/images/Products/eau_Pump3887-residentiel-Goudspumps.jpg'
        },
        {
          img:'Resources/images/Products/eau_Pump3871-residentiel-Goudspumps.jpg'
        }
      ],
      transfertPumps: [
        {
          img:'Resources/images/Products/transfertPump.jpg'
        }
      ],
      puisardPumps: [
        { img:'Resources/images/Pumps/pompePuisard.jpg' }
      ],
      securityPumps: [
        { img:'Resources/images/Pumps/pompePuisard-2.jpg' },
        { img:'Resources/images/Pumps/pompeSecurity-2.jpg' }
      ],
      turbinePumps: [
        { img:'Resources/images/Pumps/turbinePump.jpg' }
      ],
      irrigationPumps: [
        { img:'Resources/images/Pumps/pompeIrrigation.jpg' }
      ],
      Pumps: [
        {
          icon: '/Resources/images/icon/Analyse-deau.png',
          hoverIcon: '/Resources/images/icon/Analyse-deau_hover.png',
          link: '/analyseEau',
          linktitle: 'Pompe submersible'
        },
        {
          icon: '/Resources/images/icon/traitement-eau.png',
          hoverIcon: '/Resources/images/icon/traitement-eau_hover.png',
          link: '/filtre_a_eau',
          linktitle: 'Pompe égout'
        },
        {
          icon: '/Resources/images/icon/Pompe-a-eau.png',
          hoverIcon: '/Resources/images/icon/Pompe-a-eau_hover.png',
          link: '/pompe_a_eau_residentielle',
          linktitle: 'Pompe puisard',
        },
        {
          icon: '/Resources/images/icon/reparation-moteur.png',
          hoverIcon: '/Resources/images/icon/reparation-moteur_hover.png',
          link: '/reparation_pompe_de_piscine',
          linktitle: 'Pompe piston',
        },
        {
          icon: '/Resources/images/icon/reparation-moteur-electriqueV2-Noir.png',
          hoverIcon: '/Resources/images/icon/reparation-moteur-electriqueV2.png',
          link: '/reparation_moteurs_electriques',
          linktitle: 'Pompe prominent',
        },
        {
          icon: '/Resources/images/icon/pompe-industrielle.png',
          hoverIcon: '/Resources/images/icon/pompe-industrielle_hover.png',
          link: '/pompes_agricoles_commerciales_industrielles',
          linktitle: 'Pompe sécurité',
        },
        {
          icon: '/Resources/images/icon/Lavage-a-pression_icon_noir.png',
          hoverIcon: 'Resources/images/icon/Lavage-a-pression_icon.png',
          link: '/pompes_agricoles_commerciales_industrielles',
          linktitle: 'Pompe circulation chauffage',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'Pompe diaphragme',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'Pompe transfert',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'Pompe piscine',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'Pompe spa',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'Pompe irrigation',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/pieces_et_accessoires',
          linktitle: 'Pompe booster pompe',
        }
      ],
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    components: {
      contactUs,
      distributors,
      pumps
    },
  }
</script>