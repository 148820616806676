<template>
    <section style="height: auto;">
      <v-row>
        <v-col cols="12">
          <div class="boxContact">
            <div class="backgroundimg d-flex align-center justify-center">
              <h1 class="f-tierce introTitle" >{{ $t('skillsSection.Title') }}</h1>
            </div>
          </div>
          
        </v-col>
      </v-row>
      <v-row class="mb-6 gridpad2 " >     
        <v-col v-for="(item, i) in Skills" :key="i">
          <v-container class="skillItem">
            <div class="txt-c">              
              <router-link :to="item.link">
                <img class="itemHover" style="position: absolute; z-index: 2; " :src="item.hoverIcon"/>
                <img class="" style="position: relative; z-index: 1; " :src="item.icon"/>
                <h3 class="txt-c f-secondary bold skillLink">{{ $t(item.linktitle)}}</h3>
              </router-link>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </section>
</template>
<style scoped>
    .titleDist {text-align: center; font-size: 26px; font-weight: 700; background-color: rgb(var(--tierce2)); color: rgb(var(--secondary)); padding:60px 0px 60px 0px;}
    .itemHover {opacity:0;}
    .itemHover:hover {opacity:1;}
    .boxContact {
    position:relative; height:150px; width:100%;
    text-align: center;   
    background-image: url('../../public/Resources/images/General/ricardo-gomez-angel-8XDfQmFImtQ-unsplash.jpg');
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: 100%;
  }
  .backgroundimg {
    position: absolute; height:150px; width:100%;
    
    background-color: rgba(var(--secondary),0.6);
  }
  .introTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}
    /*#region Skills*/ 
    .skillLink {height:75px; font-size: 22px; vertical-align: middle; color: rgb(var(--primary)) !important;}
  .skillLink:hover {color: rgb(var(--secondary)) !important;}
  .skillItem {height: 100%; width: 100%; max-width: 400px; min-width: 300px; position: relative; display: block; margin: auto auto !important;}
  .itemHover {opacity:0;}
  .itemHover:hover {opacity:1;}
  
  /*#endregion*/ 

    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1264px) {}  
</style>
<script>
    import pageTranslations from '../../public/Resources/i18N/servicesDiapo.json';
    import banner from './bannerContactUs.vue';

  export default {
    name: 'pumpsDiapo',
    data: () => ({
      Skills: [
        {
          icon: '/Resources/images/icon/reparation-moteur-electriqueV2-Noir.png',
          hoverIcon: '/Resources/images/icon/reparation-moteur-electriqueV2.png',
          link: '/services#emergency',
          linktitle: 'skillsSection.emergency'
        },
        {
          icon: '/Resources/images/icon/Pompe-a-eau.png',
          hoverIcon: '/Resources/images/icon/Pompe-a-eau_hover.png',
          link: '/services#repairPump',
          linktitle: 'skillsSection.repairPump',
        },
        {
          icon: '/Resources/images/icon/reparation-moteur.png',
          hoverIcon: '/Resources/images/icon/reparation-moteur_hover.png',
          link: '/services#repairEngine',
          linktitle: 'skillsSection.repairEngine',
        },
        {
          icon: '/Resources/images/icon/Probleme-eau-verre.png',
          hoverIcon: 'Resources/images/icon/Probleme-eau-verre_Hover.png',
          link: '/services#artesianWell',
          linktitle: 'skillsSection.artesianWell',
        },
        {
          icon: '/Resources/images/icon/Pieces-et-accessoires.png',
          hoverIcon: '/Resources/images/icon/Pieces-et-accessoires_hover.png',
          link: '/services#pressureController',
          linktitle: 'skillsSection.pressureController',
        }
      ],
      columns: 3,
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    created() {
      window.addEventListener("resize", this.changeColumns);
    },
    destroyed() {
      window.removeEventListener("resize", this.changeColumns);
    },
    computed: {

    },
    methods:{
      changeColumns() {
        let width = window.innerWidth;
        if (width > 1800) { this.columns = 4; }
        else if (width > 1200) { this.columns = 3; }
        else if (width > 800) { this.columns = 2; } 
        else if (width < 800) { this.columns = 1; } 
      }
    },
    component: {
      banner
    }
    
  }
</script>