<template>
  <div >

    <!--Banner-->
    <section>
      <div class="topBanner"></div>
    </section>

    <!--main section-->
    <section class="bg gridpad">
      <v-row>
        <v-col cols="12" lg="6" class="txt-j d-flex align-center justify-start">
          <div class="">
            <h2 class="subtitle">{{ $t('section.title') }}</h2>
            <h3 class="mt-4 sSlogan">{{$t('section.subtitle') }}</h3>
            <p class="pb-8" v-html="$t('section.txt')"></p>
            <div class="cookieLink "><p @click="openCookie()" class="cookieLink"> Mes témoins</p></div>
            <div class="cookieLink pr-5"><p @click="navigateToPolicy()" class="cookieLink">Politique de confidentialiter</p></div>
            <div class="d-flex py-2"><img class="iconSize" src="/Resources/images/icon/location.png"/><p class="join" v-html="$t('section.address')"></p></div>
            <div class="d-flex py-2"><img class="iconSize" src="/Resources/images/icon/phone.png"/><a class="phone" href="tel:819-472-7904" v-html="$t('section.phone')"></a></div>
            <div class="d-flex py-2"><img class="iconSize" src="/Resources/images/icon/mail.png"/><a href="mailto:info@bissonservice.com"><p class="linkMail" v-html="$t('section.mail')"></p></a></div>
          </div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j d-flex align-center justify-lg-end justify-start">
          <div>
            <p v-html="$t('section.dateNumber')"></p><br>
            <p>Pour nos services d'urgence : <a class="phone" href="tel:819-472-7904" v-html="$t('section.phone')"></a> </p>
            
          </div>
        </v-col>
      </v-row>
    </section>
    <!--Form contact-->
    <section class="bgForm gridpadForm">
      <v-row>
        <v-col><h2 class="subtitle">Nous contacter</h2></v-col>
      </v-row>
      <v-row>
          <v-col cols="12">
            <form  ref="form">
              <!--Form-->
              <v-row>
                  <v-col style="">

                    <!--name-->
                    <v-row class="">

                      <v-col class="fieldMin">
                        <v-row class="padForm">
                          <p class="bold f-secondary" v-if="!error.name" style="font-size: 14px;" >Nom:</p>
                          <p class="bold error" v-if="error.name" style="font-size: 14px;">Nom:</p>
                        </v-row>
                        <v-row class="padForm">
                          <v-text-field @change="error.name = false" id="name" name="name" type="text" v-model="item.name" bg-color="blue-lighten-5" clearable required />
                          <p class="errorText" v-if="error.name" style="font-size: 14px; margin-top: -25px;">** Un nom est requis**</p>
                        </v-row>
                      </v-col>

                      <!--email-->
                      <v-col class="fieldMin">
                        <v-row class="padForm"><p class="bold f-secondary" style="font-size: 14px;">Courriel</p></v-row>
                        <v-row class="padForm">
                          <v-text-field id="Courriel" name="Courriel" type="text" v-model="item.email" bg-color="blue-lighten-5" clearable required />
                        </v-row>
                      </v-col>
                   

                      <!--city-->
                      <v-col class="fieldMin">
                        <v-row class="padForm"><p class="bold f-secondary" style="font-size: 14px;">Addresse</p></v-row>
                        <v-row class="padForm">
                          <v-text-field id="Ville" name="Ville" type="text" v-model="item.location" bg-color="blue-lighten-5" clearable required />
                        </v-row>
                      </v-col>

                      <!--phone-->
                      <v-col class="fieldMin">
                        <v-row class="padForm">
                          <p class="bold f-secondary" v-if="!error.phone" style="font-size: 14px;">Téléphone</p>
                          <p class="bold error" v-if="error.phone" style="font-size: 14px;">Téléphone</p>
                        </v-row>
                        <v-row class="padForm">
                          <v-text-field id="Telephone" name="Telephone" type="text" label="xxx xxx-xxxx" v-model="item.phone" bg-color="blue-lighten-5" clearable required />
                          <p class="errorText" v-if="error.phone" style="font-size: 14px; margin-top: -25px;">Un téléphone est requis **</p>
                        </v-row>
                      </v-col>
                      <!--problems-->
                      <v-col class="fieldMin">
                        <v-row class="padForm"><p class="bold f-secondary" style="font-size: 14px;">Service voulu</p></v-row>
                        <v-row class="padForm">
                          <v-select id="Problematiques" name="Problematiques" type="text" clearable chips v-model="item.problems" bg-color="blue-lighten-5" :items="['Traitement d’eau','Entretien et réparation pompe à eau','réparation de moteurs électriques', 'Analyse d’eau', 'Achat d’un produit', 'Contrôleur à pression constante']" multiple required />
                        </v-row>
                      </v-col>
                      <!--Description-->
                      <v-col class="fieldFormMax">
                        <v-row class="padForm"><p class="bold f-secondary" style="font-size: 14px;">Description</p></v-row>
                        <v-row class="padForm">
                          <v-textarea clearable required no-resize id="description" name="description" v-model="item.Message" label="Description"  bg-color="blue-lighten-5" rows="7" row-height="45" single-line :counter="520" maxlength="520"/>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>


              </v-row>

              <!--accept form-->
              <v-row class="d-flex flex-lg-row flex-column">
                <v-col cols="12" lg="6" class="d-flex align-center justify-md-center justify-start">
                  <p v-if="this.Dialog == 'error'" class="" style="color: red; margin-left:15px;" >Il y a eu un probleme dans la demande veuillez recommencer</p>
                </v-col>
                <v-col cols="12" lg="6" class="d-flex align-center justify-md-end justify-center">
                  <v-btn v-if="this.Dialog != 'send'" @click="this.SendForm();" class="button" style="margin-right:15px;">
                    <span class="buttonText2" v-if="this.Dialog == 'notSend' || this.Dialog == 'error'" >Envoyer</span>
                    <span class="buttonText2" v-if="this.Dialog == 'pending'">veuillez patientez pour l'envoie du formulaire</span>
                  </v-btn>
                  <h3 id="ContactFormSent" v-if="this.Dialog == 'send'" class="f-primary" style="text-align: center; font-size: 2em;">La demande à été envoyer !</h3>
                </v-col>
              </v-row>
            </form>
          </v-col>
        </v-row>

    </section>
    <!--section4 Map-->
    <section>
      <iframe class="map" style="border:0; margin-bottom: -4px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2777.872604134693!2d-72.518639108993!3d45.87385833146289!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc813e3b04681df%3A0x2225e244fdb72350!2s924%20Rue%20Saint-Pierre%2C%20Drummondville%2C%20QC%20J2C%204A5!5e0!3m2!1sfr!2sca!4v1691589950088!5m2!1sfr!2sca"></iframe>
    </section>
  </div>
</template>

<style scoped>
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .map {width:100%; height:calc(150px + 20vw);}
  .gridpad {padding: 100px 200px 0px 200px;}
  .sSlogan {color: rgba(var(--menuColor),0.8); font-weight: 500; font-size: 22px; padding: 0px 0px 30px 0px;}
  .subtitle {line-height: 25px;}
  .topBanner {height: 450px; background-image: url('../../public/Resources/images/Banner/bannerContactUs.jpg'); background-position: center; background-position-y: 65%; background-repeat: no-repeat; background-size: cover;}
/*#region section4 Quality*/ 
  .iconSize {height:16px; width:16px; margin: 5px 0px 0px 0px;}
  .bg{background-color: rgb(var(--tierce2)); height:600px;}
  .join {font-size: 18px; color: rgb(var(--secondary)); font-weight: 700; padding: 0px 0px 0px 10px;}
  .phone {font-size: 18px; color: rgb(var(--secondary)); font-weight: 700; padding: 0px 0px 0px 10px;}
  .phone:hover { color: rgb(var(--primary)); }
  .linkMail {font-size: 18px; color: rgb(var(--secondary)); font-weight: 700; padding: 0px 0px 0px 10px;}
  .linkMail:hover {color: rgb(var(--primary));}
  .cookieLink { color: rgb(var(--secondary)) ; text-decoration: none ; cursor: pointer;}
    .cookieLink:hover { text-decoration: underline !important; color: rgb(var(--primary));}
  /*#endregion*/ 
  /*#region Form*/
    .gridpadForm {padding: 50px 200px;}
    .bgForm {background-color: rgb(var(--tierce2));}
    .fieldMin { min-width: 50%; }
    .fieldFormMax { min-width: 100%; }
    .padForm { padding: 5px 10px;}
  /*#endregion*/ 

@media handheld, only screen and (max-width: 700px) {
  .textPadLeft { padding: 200px 30px 0px 30px !important;}
  .fieldMin { min-width: 100%; }
}

@media handheld, only screen and (max-width: 960px) {
  .gridpad {padding: 30px !important;}
  .gridpadForm {padding: 10px !important;}
}

@media handheld, only screen and (max-width: 1279px) {
  .gridpad {padding: 50px 200px 50px 200px;}
  .bg{background-color: rgb(var(--tierce2)); height:100%;}
  
}
@media handheld, only screen and (max-width: 1378px) {}
</style>

<script>
  import pageTranslations from '../../public/Resources/i18N/contact.json';
  export default {
    name: 'contact',
    data: () => ({
      item: {
        name: null,
        email: null,
        phone: null,
        address: null,
        location: null,
        treatment: null,
        problems: null,
        Message: null
      },
      Dialog: "notSend", //"error, send, notSend"
      error:{
        name: false,
        phone: false
      },
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    methods: {
      validation: async function(){
        let valid = true;
        let errorFlag = {name: false, phone: false};
        if(this.item.name == null || this.item.name.length == 0){ valid = false; errorFlag.name = true; }
        if(this.item.phone == null || this.item.phone.length < 12){valid = false; errorFlag.phone = true; }
        this.error = errorFlag;
        return valid;
      },
      login: async function () {

        let api = 'https://abstracxion.wx3data.com/security/login/BissonService'; // need Tenant BissonService 
        let data = null;
        try {
            let response = await fetch(api, {
                method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify({LoginName: "", password: ""}) // body data type must match "Content-Type" header
            })
            if (await response.ok) {
                if (await response.status !== 204) {
                    let data = await response.text();
                    return data
                }
            } else {
                let msg = await response.text();
                $App.event.$emit("app_error", msg);
            };
                
        } catch (error) {
            console.log(error);
        }
      },
      SendForm: async function (){
        this.Dialog = "pending";
        let valid = await this.validation();
        if(valid == true){
          let formData = new FormData(this.$refs.form);
          /*
          setTimeout(() => {
            this.Dialog = "send";
          }, 3000);*/ 

          let api = 'https://abstracxion.wx3data.com/eCommerce/Forms/ContactUs/sendByEmail';
          try {
              let token = await this.login();

              // ** ATTENTION need to add bearer token...
              let response = await fetch(api, {
                  method: 'POST',
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                      'Authorization': 'Bearer ' + token
                  },
                  redirect: 'follow', // manual, *follow, error
                  referrerPolicy: 'no-referrer', // no-referrer, *client
                  body: formData
              });

              if (await response.ok) {
                  if (await response.status !== 204) {
                    this.Dialog = "send";
                  }
              } else {
                this.Dialog = "error";
              };

          } catch (error) {

          }
        }else { this.Dialog = 'error'; }

      },
    },
    components: {},
  }
</script>
  
  