<template>
  <div >
    <!--Intro-->
    <section class="v-primary-light-1 gridpad">
      <v-row>
        <v-col cols="12" class=" ">
          <div class="txt-c mt-11">
            <h1 class="titleIntro gridpadSub">{{ $t('intro.title') }}</h1>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--armstrong-->
    <section id="armstrong" class="bgLogo gridpad ">
      <v-row class="d-flex justify-center">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div class="armstrongBox">
            <h2 class="armstrongTitle">{{ $t('pumpArmstrong.title') }}</h2>
            <p class="mt-5" v-html="$t('pumpArmstrong.text')"></p>
            <ol class="olpad">
              <li v-html="$t('pumpArmstrong.ol1')"></li>
              <li v-html="$t('pumpArmstrong.ol2')"></li>
              <li v-html="$t('pumpArmstrong.ol3')"></li>
              <li v-html="$t('pumpArmstrong.ol4')"></li>
              <li v-html="$t('pumpArmstrong.ol5')"></li>
              <li v-html="$t('pumpArmstrong.ol6')"></li>
              <li v-html="$t('pumpArmstrong.ol7')"></li>
              <li v-html="$t('pumpArmstrong.ol8')"></li>
            </ol>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--Section4 ContactUs-->
    <contactUs/>
    <!--accessoires-->
    <section class="">
      <v-row class="d-flex justify-center">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div class="armstrongBox">
            <h2 class="armstrongTitle">{{ $t('Accessoires.title') }}</h2>
          </div>
        </v-col>
      </v-row>
      <armstrong/>
    </section>
    
  </div>
</template>

<style scoped>
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .subtitle {line-height: 30px;}
  .gridpad {padding-bottom: 50px;}
  .topBanner {height: 450px; background-image: url('../../public/Resources/images/Banner/bannerHighPressureWasher.jpg'); background-position: center; background-position-y: 100%; background-repeat: no-repeat; background-size: cover;}
  .bgLogo{position: relative;}
  .bgLogo::before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    transform: rotate(-5deg);
    background-image: url('../../public/Resources/images/Products/armstrong/armstrong_logo_blackred.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  /*#region Intro*/ 
  .titleIntro {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}
  /*#endregion*/ 
 
    /*#region easyClean*/
  
    #armstrong ol li {padding: 10px 0px;}
    .armstrongBox { padding: 0px 0px 32px 0px;}
    .armstrongPad {text-align: justify; padding: 15px 50px 15px 50px;}
    .armstrongTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px; text-align: center;}
/*#endregion*/ 
    .olpad { text-align: justify; padding: 20px 40px;  }
@media handheld, only screen and (max-width: 600px) {
    .olpad { padding: 20px 15px;  }
  }

  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 25px 25px 50px 25px;}
  }

  @media handheld, only screen and (max-width: 1279px) {
  }
  @media handheld, only screen and (max-width: 1378px) {
  }
</style>

<script>
    import pageTranslations from '../../public/Resources/i18N/armstrong.json'
    import distributors from '../components/distributors.vue';
    import contactUs from '@/components/bannerContactUs.vue';
    import armstrong from '@/components/armstrong.vue';

  export default {
    name: 'artesianWells',
    data: () => ({

    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    components: {
      contactUs,
      armstrong,
      distributors
    },
  }
</script>