<template>
    <section style="height: auto;">
      <v-carousel cycle :show-arrows="false" hide-delimiters infinite style=" padding-top: 20px;">
        <template v-for="(item, index) in Dist"> 
          <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
            <v-row class="flex-nowrap" style="height:100%">
              <template v-for="(n,i) in columns"> 
                <template v-if="(+index + i) < Dist.length"> 
                  <v-col :key="i">
                    <v-sheet v-if="(+index + i) < Dist.length"  height="100%" >
                      <v-row class="" justify="center">
                        <div class="display-3" style="">
                            <!--ici ton image ou information necessaire-->
                          <img class="" style="position: relative; z-index: 1; max-width: 250px; height: auto;" :src="Dist[index + i].img"/>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-carousel-item>
        </template>
      </v-carousel>
    </section>
</template>
<style scoped>
    .titleDist {text-align: center; font-size: 26px; font-weight: 700; background-color: rgb(var(--tierce2)); color: rgb(var(--secondary)); padding:60px 0px 60px 0px;}
    .itemHover {opacity:0;}
    .itemHover:hover {opacity:1;}
    .boxContact {
    position:relative; height:150px; width:100%;
    text-align: center;   
    background-image: url('../../public/Resources/images/General/ricardo-gomez-angel-8XDfQmFImtQ-unsplash.jpg');
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: 100%;
  }
  .backgroundimg {
    position: absolute; height:150px; width:100%;
    
    background-color: rgba(var(--secondary),0.6);
  }
  .introTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}

    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1264px) {}  
</style>
<script>
    import pageTranslations from '../../public/Resources/i18N/pumpsDiapo.json';
    import banner from './bannerContactUs.vue';

  export default {
    name: 'pumpsDiapo',
    data: () => ({
      Dist: [
        {
          title:'accesories-1',
          img:'/Resources/images/Products/accessoires/accesories1.jpg',
        },
        {
          title:'accesories-2',
          img:'/Resources/images/Products/accessoires/accesories2.jpg',
        },
        {
          title:'accesories-3',
          img:'/Resources/images/Products/accessoires/accesories3.jpg',
        },
        {
          title:'accesories-4',
          img:'/Resources/images/Products/accessoires/accesories4.jpg',
        },
        {
          title:'accesories-5',
          img:'/Resources/images/Products/accessoires/accesories5.jpg',
        },
        {
          title:'accesories-6',
          img:'/Resources/images/Products/accessoires/accesories6.jpg',
        }
      ],
      columns: 3,
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    created() {
      window.addEventListener("resize", this.changeColumns);
    },
    destroyed() {
      window.removeEventListener("resize", this.changeColumns);
    },
    computed: {

    },
    methods:{
      changeColumns() {
        let width = window.innerWidth;
        if (width > 1800) { this.columns = 4; }
        else if (width > 1200) { this.columns = 3; }
        else if (width > 800) { this.columns = 2; } 
        else if (width < 800) { this.columns = 1; } 
      }
    },
    component: {
      banner
    }
    
  }
</script>