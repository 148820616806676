<template>
    <div>
        <v-row class="ma-0 gridpad" >
            <v-col cols="12" lg="5" class="pa-0 d-flex align-center">
                <v-container id="image">
                    <div v-if="this.image" class="whiteBG d-flex justify-center"><v-img class="productImg" :src="this.image"></v-img></div>                 
                </v-container>
            </v-col>
            <v-col cols="12" lg="7" style="position:relative">
                <div class="gridpadProduct">

                    <!--Title-->
                    <v-row>
                        <v-col><h2 id="text" class="productTitle">{{Product.title}}</h2></v-col>
                    </v-row>

                    <!--Description-->
                    <v-row>
                        <v-col><p class="txt-j" style="min-height: 250px; padding-top: 20px;" v-html="$filters.textWithBR(Product.description)"></p></v-col>
                    </v-row>
                    <v-row v-if="this.Product.included">
                        <v-col cols="12" class="">
                            <button style="width: 100%;" @click="showSelectedInclued = true"  class="mt-1">
                                <div  class="btnKit">
                                    <h2 class="kitText d-flex align-center justify-center" v-html="Inclus"></h2>                   
                                </div>
                            </button>
                        </v-col>
                    </v-row>

                    <div class="mt-10">
                        <v-col cols="12" class="d-flex justify-center"><div class="phone" style=""><p class="phoneTitle">Appelez pour réserver <a class="phoneTitle" href="tel:819472-7904">819-472-7904</a></p></div></v-col>             
                        <v-col cols="12" class="d-flex justify-center"><div style="width: 80%; margin: 0px 0px 10px 0px; border-bottom: solid 2px rgb(var(--primary));"></div></v-col>
                        <v-row class="ma-0">
                            <v-col v-if="Product.price > 0"  class="d-flex justify-end align-center py-0"><h2 class="priceText">{{Product.price}}$</h2></v-col> <!---->
                        </v-row>

                        <v-row class="ma-0">
                            <v-col class="d-flex justify-center justify-sm-end py-0"><p @click="navigateBack()" class="backTitle" >Retour</p></v-col>
                        </v-row>
                    </div>

                </div>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
  .gridpad {padding: 100px 100px;}
  .gridpadProduct { padding: 0px 25px; }
  .productImg { width: 100%; height: auto; max-width: 700px; max-height: 800px; object-fit: contain !important; object-position: top;}
  .productTitle { text-align: center; font-weight: 700; color: rgb(var(--secondary)); animation: an 1s ease-out 1 both; }
  .phoneTitle { text-align: center; font-weight: 700; color: rgb(var(--primary)); text-shadow: 1px 0px 1px rgb(var(--secondary)); font-size:20px; }
  .priceText {color: rgb(var(--primary)); text-shadow: 1px 1px 4px #000; font-size:36px; text-align: center; }
  .backTitle { text-transform: uppercase; cursor: pointer;}
  .backTitle:hover { color: rgb(var(--primary)); text-decoration: underline; }
  .phone { width: 80%; margin: 30px 0px 10px 0px; text-align: center; }
  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 100px 5px 5px 5px !important;}
    .phone { width: 100%; }
  }

  @keyframes an{
        from{
            opacity: 0;
            transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
        }
        to{
            opacity: 1;
            transform: perspective(500px) translate3d(0, 0, 0);
        }
    }
</style>
<script>
    import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'
    export default {
        name: 'productDetailed',
        mixins: [apiECommerceExtension],
        data: () => ({
            Product: {title:"product"} ,
            showSelectedInclued: false,
            image: 'https://abstracxionstorage.blob.core.windows.net/bissonservice/products/NoImage-BissonService.jpg',
            loading: false,
        }),
        mounted: async function() {
            this.loading = true;

            this.Product = await this.EcommerceProductGetById();
            if(this.Product.image.url){this.image = this.Product.image.url;}
            if(this.Product) { this.loading = false; this.animation(); }
        },
        methods: {
            navigateBack: function (route) {
                if (window.history.length > 1) {
                    this.$router.go(-1);
                } else if (route) {
                    this.$router.push(this.$router.push({ name: 'products'}));
                }
            },
            animation: function () {
                var text = document.getElementById('text');
                var newDom = '';
                var animationDelay = 6;

                for(let i = 0; i < text.innerText.length; i++)
                {
                    newDom += '<span class="productTitle">' + (text.innerText[i] == ' ' ? '&nbsp;' : text.innerText[i])+ '</span>';
                }

                text.innerHTML = newDom;
                var length = text.children.length;

                for(let i = 0; i < length; i++)
                {
                    text.children[i].style['animation-delay'] = animationDelay * i + 'ms';
                }
            }
        }
    }
</script>