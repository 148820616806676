export const apiECommerceExtension = {
    created: function () {
    },
    methods: {
        login: async function () {
            let api = $App.url.api + 'security/login/BissonService';
            let data = null;
            try {
                let response = await fetch(api, {
                    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *client
                    body: JSON.stringify({LoginName: "", password: ""}) // body data type must match "Content-Type" header
                })
                if (await response.ok) {
                    if (await response.status !== 204) {
                        let data = await response.text();
                        return data
                    }
                } else {
                    let msg = await response.text();
                    $App.event.$emit("app_error", msg);
                };
                    
            } catch (error) {
                console.log(error);
            }
        },
        EcommerceProductGetById: async function(){
            try {

                let data = null;
                let id = this.$route.params.id;
                let api = $App.url.api + "eCommerce/Products/"+ this.$route.params.id;
                //https://abstracxion.wx3data.com/ || http://localhost:17548/ || Tenant/FBergeron/Sale/Products/filter
                try {
                    let token = await this.login();

                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'GET',
                        headers: {
                          'Authorization': 'Bearer ' + token,
                          'Content-Language': this.$i18n.locale
                        },
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                            data = await response.json();
                        }
                    } else {
                        //let msg = await response.text();
                    };

                } catch (error) {
                    $App.event.$emit("app_error", error);
                }

                return data;

            } catch (error) {
                $App.event.$emit("app_error", error);
                return null;
            }
        },
        EcommerceProductsList: async function(range,callbackfct){
            console.log('ProductList');
            try {
                let data = null;
                let api = $App.url.api + 'eCommerce/Products/filter';
                let filter = {"categoryId": null};
    
                try {
                    let token = await this.login();
                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'PUT',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                            'Range': range,
                            'Content-Language': this.$i18n.locale
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                        body: JSON.stringify(filter)
                    });
    
                    if (await response.ok) {
                        if (await response.status !== 204) {
                            data = await response.json();
                            if(callbackfct) { callbackfct(response); }
                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };
    
                } catch (error) {
                    $App.event.$emit("app_error", error);
                }
    
                return data;
    
            } catch (error) {
                $App.event.$emit("app_error", error);
                return null;
            }
        },
        EcommerceProductsListWithFilter: async function(filter,range,callbackfct){
            try {
                let data = null;
                let api = $App.url.api + 'eCommerce/Products/filter';
                let Filter = filter;
    
                try {
                    let token = await this.login();
                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'PUT',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                            'Range': range,
                            'Content-Language': this.$i18n.locale
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                        body: JSON.stringify(Filter)
                    });
    
                    if (await response.ok) {
                        if (await response.status !== 204) {
                            data = await response.json();
                            if(callbackfct) { callbackfct(response); }
                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };
    
                } catch (error) {
                    $App.event.$emit("app_error", error);
                }
    
                return data;
    
            } catch (error) {
                $App.event.$emit("app_error", error);
                return null;
            }
        },
        EcommerceCategoriesList: async function(){
            try {
                let data = null;
                let api = $App.url.api + 'eCommerce/ProductCategories';
    
                try {
                    let token = await this.login();
                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'GET',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                            'Content-Language': this.$i18n.locale
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                    });
    
                    if (await response.ok) {
                        if (await response.status !== 204) {
                            data = await response.json();
                        }
                    } else {
                        let msg = await response.text();
                    };
    
                } catch (error) {
                    $App.event.$emit("app_error", error);
                }
    
                return data;
    
            } catch (error) {
                $App.event.$emit("app_error", error);
                return null;
            }
        },
    }
}
