<template>
  <div >
    <!--Section1 Banner-->
    <section>
      <div class="topBanner"></div>
      <v-row class="bannerTextPad">
        <v-col cols="12">
          <div class="txt-c ">
            <img class="iconBanner" src="/Resources/images/icon/Analyse-deau_hover.png"/>
          </div>
          <h1 class="txt-c bannerTitle">{{ $t('sectionBanner.Title') }}</h1>
        </v-col>
      </v-row>
    </section>

    <!--Section3 Why-->
    <section class="boxSectionWhy pt-3">
      <v-row class="d-flex flex-wrap-reverse justify-center">
        <v-col class="txt-j sectionWhyTextPad minBoxImg">
          <div>
            <h2 class="subtitle">{{ $t('sectionWhy.title') }}</h2>
            <h3 class="sSlogan">{{ $t('sectionWhy.slogan') }}</h3>
            <h4 class="sectionWhyTitlePar">{{ $t('sectionWhy.physicochimiqueSubtitle') }}</h4>
            <p class="" v-html="$t('sectionWhy.physicochimiqueTxt')"></p>
            <p class="pt-4 pb-4" v-html="$t('sectionWhy.procedureTxt')"></p>
            <router-link to="analyseEau/#Form" class="button buttonText">{{ $t('sectionWhy.linkAnalysis') }}</router-link>
          </div>
        </v-col>
        <v-col class="py-0 minBoxImg">
          <div class="bgSectionWhyPhoto"></div>
        </v-col>
      </v-row>
    </section>

    <!--Section4 NewHome-->
    <section class="bgSectionNewHome">
      <v-row class="d-flex justify-center">
        <v-col  class="py-0 minBoxImg">
          <div class="bgSectionNewHomePhoto1"></div>
        </v-col>
        <v-col class="txt-j sectionNewHomeTextPad minBoxImg">
          <v-row>
            <h2 class="sectionNewBacTitle">{{ $t('sectionWhy.bacteriologicalSubtitle') }}</h2>
            <p class="pb-4" v-html="$t('sectionWhy.bacteriologicalTxt')"></p>
            <router-link to="/contact" class="button buttonText">{{ $t('sectionWhy.linkContactUs') }}</router-link>
          </v-row>
          <v-row class="mt-10">
            <h2 class="sectionNewHomeTitle">{{ $t('sectionNewHome.title') }}</h2>
            <p class="pb-4" v-html="$t('sectionNewHome.txt')"></p>
          </v-row>
        </v-col>
      </v-row>
    </section>

    <!--TypeWater-->
    <section class="gridpad2" id="TypeWater">
      <v-row class="mb-6">
        <v-col cols="12">
          <div class="typeTitle">
            <img class="iconFormat" src="/Resources/images/icon/Probleme-eau-verre_Hover.png"/>
            <h2 class="subtitle">{{ $t('sectionTypeWater.title') }}</h2>
            <h3 class="subtitle3">{{ $t('sectionTypeWater.subtitle') }}</h3>
          </div>
        </v-col>

        <v-col v-for="(item, i) in Types" :key="i" >
          <v-container class="typeItem pa-0 d-flex justify-start" >
            <div>              
                <img class="typeImg" :src="item.img"/>
            </div>
            <div class="typeBoxTxt">
              <h3 class="subtitle2">{{ $t(item.subtitle)}}</h3>
              <p class="txt-j" v-html="$t(item.txt)"></p>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </section>

    <!--Form-->
    <section id="Form"  style="margin: 10px;">
      <v-row class="gridpad2">
        <h2 class="subtitle">{{ $t('form.title') }}</h2>
      </v-row>
      <div class="gridpad mt-5">
        
        <v-row>
          <v-col cols="12"><p v-html="$t('form.select')"></p></v-col>
          <v-col cols="12">
            <v-select v-model="select2" :items="ItemChoix2"  item-title="title" item-value="value" label="Choix" variant="outlined" return-object></v-select>
          </v-col>
        </v-row>
        <v-row v-if="select2.value == '1'">
          <v-col><p v-html="$t('form.selectLocalChoice')"></p></v-col>
        </v-row>
        <v-row v-if="select2.value == '0'">
          <v-col cols="12">
            <form  ref="form">
              <!--Form-->
              <v-row>
                  <v-col cols="12" lg="6" class="px-5">

                    <!--name-->
                    <v-row class="">
                      <v-col cols="12" class="">
                        <p class="bold f-secondary" v-if="!error.name" style="font-size: 14px;" v-html="$t('form.name')"></p>
                        <p class="bold error" v-if="error.name" style="font-size: 14px;" v-html="$t('form.name')"></p>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field @change="error.name = false" id="name" name="name" type="text" v-model="item.name" bg-color="blue-lighten-5" clearable required />
                        <p class="errorText" v-if="error.name" style="font-size: 14px; margin-top: -25px;">** Un nom est requis**</p>
                      </v-col>

                    <!--email-->
                      <v-col cols="12"><p class="bold f-secondary" style="font-size: 14px;" v-html="$t('form.mail')"></p></v-col>
                      <v-col cols="12"><v-text-field id="Courriel" name="Courriel" type="text" v-model="item.email" bg-color="blue-lighten-5" clearable required /></v-col>
                    
                    <!--phone-->
                      <v-col cols="12">
                        <p class="bold f-secondary" v-if="!error.phone" style="font-size: 14px;" v-html="$t('form.phone')"></p>
                        <p class="bold error" v-if="error.phone" style="font-size: 14px;" v-html="$t('form.phone')"></p>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field id="Telephone" name="Telephone" type="text" label="xxx xxx-xxxx" v-model="item.phone" bg-color="blue-lighten-5" clearable required />
                        <p class="errorText" v-if="error.phone" style="font-size: 14px; margin-top: -25px;">Un téléphone est requis **</p>
                      </v-col>

                    <!--city-->
                      <v-col cols="12"><p class="bold f-secondary" style="font-size: 14px;" v-html="$t('form.city')"></p></v-col>
                      <v-col cols="12"><v-text-field id="Ville" name="Ville" type="text" v-model="item.location" bg-color="blue-lighten-5" clearable required /></v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" lg="6" class="px-5">
                    <!--treatment-->
                    <v-row>
                      <v-col cols="12"><p class="bold f-secondary" style="font-size: 14px;" v-html="$t('form.system')"></p></v-col>
                      <v-col cols="12"><v-select id="treatment" name="treatment" type="text" v-model="item.treatment" bg-color="blue-lighten-5" :items="[$t('form.systemYes'), $t('form.systemNo')]"  clearable required /></v-col>
                    </v-row>

                    <!--problems-->
                    <v-row>
                      <v-col cols="12"><p class="bold f-secondary" style="font-size: 14px;" v-html="$t('form.problems')"></p></v-col>
                      <v-col cols="12"><v-select id="Problematiques" name="Problematiques" type="text" clearable chips v-model="item.problems" bg-color="blue-lighten-5" :items="[$t('form.problemLimestone'), $t('form.problemRust'), $t('form.problemChlorine'), $t('form.problemYellow'), $t('form.problemCorrosive')]" multiple required /></v-col>
                    </v-row>

                    <!--describe-->
                    <v-row>
                      <v-col cols="12"><p class="bold f-secondary" style="font-size: 14px;" v-html="$t('form.problemsDesc')"></p></v-col>
                      <v-col cols="12"><v-textarea clearable required no-resize id="description" name="description" v-model="item.Message" label="Description"  bg-color="blue-lighten-5" rows="7" row-height="45" single-line :counter="520" maxlength="520"/></v-col>
                    </v-row>
                  </v-col>
              </v-row>

              <!--accept form-->
              <v-row class="d-flex flex-lg-row flex-column">
                <v-col cols="12" lg="6" class="d-flex align-center justify-md-center justify-start">
                  <p v-if="this.Dialog == 'error'" class="" style="color: red; margin-left:15px;" >{{ $t('form.error') }}</p>
                </v-col>
                <v-col cols="12" lg="6" class="d-flex align-center justify-md-end justify-center">
                  <v-btn v-if="this.Dialog != 'send'" @click="this.SendForm();" class="button" style="margin-right:15px;">
                    <span class="buttonText2" v-if="this.Dialog == 'notSend' || this.Dialog == 'error'" >{{ $t('form.send') }}</span>
                    <span class="buttonText2" v-if="this.Dialog == 'pending'">{{ $t('form.wait') }}</span>
                  </v-btn>
                  <h3 id="waterAnlysisFormSent" v-if="this.Dialog == 'send'" class="f-primary" style="text-align: center; font-size: 2em;">{{ $t('form.complete') }}</h3>
                </v-col>
              </v-row>
            </form>
          </v-col>
        </v-row>
      </div>
      
    </section>
  </div>
</template>
<style scoped>
  .minBoxImg { min-width: 525px; }
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .gridpad2 {padding: 0px 50px;}
  .gridpad3 {padding: 0px 350px;}
  .iconFormat {width: 50px; height: 50px; margin-bottom: -15px;}
  .subtitle {line-height: 35px;}
  /*#region section Banner*/ 
  .topBanner {height: 450px; background-image: url('../../public/Resources/images/WaterAnalysis/banner_analysis.jpg'); background-position: center; background-position-y: 100%; background-repeat: no-repeat; background-size: cover;}
  .bannerTextPad {padding:45px 0px 60px 0px;}
  .bannerTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}
  .iconBanner {width: 50px; height: 50px; margin-bottom: -10px;}
  /*#endregion*/ 

  /*#region section Question*/ 
  .bgQuestion {background-color: rgb(var(--tierce2));}
  .sectionQuestionPadLeft {padding: 50px 0px 50px 0px; }
  .sectionQuestionPadRight {padding: 50px 0px 50px 0px;}
  
  /*#endregion*/ 

  /*#region section Why*/ 
  .boxSectionWhy {padding: 0px 0px 22px 0px;}
  .sectionWhyTextPad { padding: 50px;}
  .sSlogan {color: rgba(var(--menuColor),0.8); font-weight: 500; font-size: 22px; padding: 0px 0px 15px 0px;}
  .sectionWhyTitlePar {color: rgba(var(--menuColor),0.8); font-weight: 700; font-size: 22px; padding: 15px 0px 0px 0px;}
  .bgSectionWhyPhoto {background-image: url("../../public/Resources/images/WaterAnalysis/child_water.jpg"); height:100%; width:100%; background-position: center; background-repeat: no-repeat; background-size:cover;}
  /*#endregion*/ 

  .sectionNewBacTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px; text-transform: uppercase; padding: 0px 0px 15px 0px;}
  /*#region section NewHome*/ 
  .sectionNewHomeTitle {color: rgba(var(--menuColor),0.8); font-weight: 700; font-size: 22px; text-transform: uppercase; padding: 0px 0px 15px 0px;}
  .sectionNewHomeSubtitle {color: rgba(var(--menuColor),0.8); font-weight: 500; font-size: 18px;}
  .bgSectionNewHome {background-color: rgb(var(--tierce2));}
  .bgSectionNewHomePhoto1 {background-image: url("../../public/Resources/images/WaterAnalysis/Maison-Neuve-Analyse-eau.jpg"); height:100%; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .sectionNewHomeTextPad { padding: 50px;}
  /*#endregion*/ 

  /*#region section Problem*/ 
  /*#endregion*/ 

  /*#region TypeWater*/ 
  .typeTitle {text-align: center; padding:50px 0px 50px 0px;}
  .typeItem {height: 150px; width: 100%; max-width: 450px; min-width: 450px; position: relative; display: block; margin: auto auto !important;}
  .typeImg {height:150px; width: 150px;}
  .typeBoxTxt {padding: 0px 0px 0px 30px;}

  /*#endregion*/

/*#region Form*/ 
  
  /*#endregion*/ 

@media handheld, only screen and (max-width: 600px) {
    .minBoxImg { min-width: 100%; margin-left: auto; margin-right: auto; display: block;}
    .gridpad3 {padding: 0px 0px 0px 0px !important;}
    .typeTitle {text-align: center; padding:50px 0px 0px 0px;}
}

@media handheld, only screen and (max-width: 960px) {
  .gridpad {padding: 0px 25px 50px 25px;}
  .gridpad3 {padding: 0px 0px 0px 0px }
}

@media handheld, only screen and (max-width: 1279px) {
  .bgSectionWhyPhoto {height:350px; background-position-y: 65%; background-repeat: no-repeat; background-size:100%;}
  .bgSectionNewHomePhoto1 {height:350px; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .gridpad2 {padding: 0px 25px 50px 25px;}

  .sectionNewHomeTextPad { padding: 15px 25px 45px 25px;}
  .boxTypeTitle {padding: 0px 30px; text-align: center;}

}
@media handheld, only screen and (max-width: 1378px) {
  .sectionWhyTextPad { padding: 25px 30px 30px 30px;}
}
</style>

<script>
    import pageTranslations from '../../public/Resources/i18N/waterAnalysis.json';
  export default {
    name: 'waterAnalysis',
    data: () => ({
      select2 :  {title: 'Locataire / Tenant', value: '1'},
      cart:null,
      item: {
        name: null,
        email: null,
        phone: null,
        address: null,
        location: null,
        treatment: null,
        problems: null,
        Message: null
      },
      Dialog: "notSend", //"error, send, notSend"
      error:{
        name: false,
        phone: false
      },
      key: "", //need to add key
      showForm: true,
      ItemChoix2: [
        {title: 'Proprietaire / Homeowner', value: '0'},
        {title: 'Locataire / Tenant', value: '1'},
      ],
      Types: [
        {
          img: '/Resources/images/WaterAnalysis/typeWater_calcaire.jpg',
          subtitle: 'sectionTypeWater.subLimestone',
          txt: 'sectionTypeWater.txtLimestone'
        },
        {
          img: '/Resources/images/WaterAnalysis/typeWater_rust.jpg',
          subtitle: 'sectionTypeWater.subRust',
          txt: 'sectionTypeWater.txtRust'
        },
        {
          img: '/Resources/images/WaterAnalysis/typeWater_chlore.jpg',
          subtitle: 'sectionTypeWater.subChlorine',
          txt: 'sectionTypeWater.txtChlorine'
        },
        {
          img: '/Resources/images/WaterAnalysis/typeWater_odor.jpg',
          subtitle: 'sectionTypeWater.subOdor',
          txt: 'sectionTypeWater.txtOdor'
        },
        {
          img: '/Resources/images/WaterAnalysis/typeWater_jaune.jpg',
          subtitle: 'sectionTypeWater.subYellow',
          txt: 'sectionTypeWater.txtYellow'
        },
        {
          img: '/Resources/images/WaterAnalysis/typeWater_corrosive.jpg',
          subtitle: 'sectionTypeWater.subCorrosive',
          txt: 'sectionTypeWater.txtCorrosive'
        },
        {
          img: '/Resources/images/WaterAnalysis/typeWater_nonpotable.jpg',
          subtitle: 'sectionTypeWater.subNonPotable',
          txt: 'sectionTypeWater.txtNonPotable'
        }
      ],
      

      
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    methods: {
      validation: async function(){
        let valid = true;
        let errorFlag = {name: false, phone: false};
        if(this.item.name == null || this.item.name.length == 0){ valid = false; errorFlag.name = true; }
        if(this.item.phone == null || this.item.phone.length < 12){valid = false; errorFlag.phone = true; }
        this.error = errorFlag;
        return valid;
    },
      login: async function () {

        let api = 'https://abstracxion.wx3data.com/security/login/BissonService'; // need Tenant BissonService 
        let data = null;
        try {
            let response = await fetch(api, {
                method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify({LoginName: "", password: ""}) // body data type must match "Content-Type" header
            })
            if (await response.ok) {
                if (await response.status !== 204) {
                    let data = await response.text();
                    return data
                }
            } else {
                let msg = await response.text();
                $App.event.$emit("app_error", msg);
            };
                
        } catch (error) {
            console.log(error);
        }
    },
      SendForm: async function (){
      this.Dialog = "pending";
      let valid = await this.validation();
      if(valid == true){
        let formData = new FormData(this.$refs.form);
        /*
        setTimeout(() => {
          this.Dialog = "send";
        }, 3000);*/ 

        let api = 'https://abstracxion.wx3data.com/eCommerce/Forms/ContactUs/sendByEmail';
        try {
            let token = await this.login();

            // ** ATTENTION need to add bearer token...
            let response = await fetch(api, {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: formData
            });

            if (await response.ok) {
                if (await response.status !== 204) {
                  this.Dialog = "send";
                }
            } else {
              this.Dialog = "error";
            };

        } catch (error) {

        }
      }else { this.Dialog = 'error'; }

    },
    },
    components: {},
  }
</script>