<template>
  <div >
    <!--Banner-->
    <section>
      <div class="topBanner"></div>
    </section>

    <!--Intro-->
    <section class="gridpad">
      <v-row>
        <v-col cols="12" class=" ">
          <div class="introBox">
            <img class="icon" src="/Resources/images/icon/Pompe-a-eau_hover.png"/>
            <h1 class="introTitle">{{ $t('intro.title') }}</h1>
            <p class="txtAlign" v-html="$t('intro.txt')"></p>
          </div>
        </v-col>
      </v-row>
    </section>

    <!--SubmersiblePump-->
    <section class="v-tierce2">
      <v-row class="">
        <v-col cols="12" lg="6" class="pa-0 d-flex align-center justify-center">
          <div class="subImg"></div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j">
          <div class="subPad">
            <h2 class="subTitle mb-2">{{ $t('submersiblePump.title') }}</h2>
            <p v-html="$t('submersiblePump.txt')"></p>
            <router-link class="button mt-4" to="/contact" ><p class="buttonText" v-html="$t('submersiblePump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
    </section>
    
    <!--residentialPump-->
    <section>
      <v-row class="d-flex flex-wrap-reverse justify-center gridpad3">
        <v-col cols="12" lg="6" class="txt-j">
          <v-row class="resPad">
            <v-col cols="12">
                <h2 class="resTitle mb-2">{{ $t('residentialPump.title') }}</h2>
                <p v-html="$t('residentialPump.txt')"></p>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="6" class="px-0 d-flex justify-end"><div class="amstrong1"></div></v-col>
                <v-col cols="6" class="px-0 d-flex justify-start"><div class="amstrong2"></div></v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="txt-c">
              <router-link class="button" to="/contact" ><p class="buttonText" v-html="$t('residentialPump.btn')"></p></router-link>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" class="">
          <v-row class="my-0 d-flex flex-wrap justify-center justify-center">
            <v-col cols="12" class="py-0">
              <div class="resImg"></div>
            </v-col>
            <v-col cols="12" class=" px-0">
              <v-row class="mx-0">
                <v-col cols="4" class="pa-0 d-flex flex-wrap justify-center"><div class="resPump1"></div></v-col>
                <v-col cols="4" class="pa-0 d-flex flex-wrap justify-center"><div class="resPump2"></div></v-col>
                <v-col cols="4" class="pa-0 d-flex flex-wrap justify-center"><div class="resPump3"></div></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    
    <!--otherPump-->
    <section class="v-tierce2">
      <v-row>
        <v-col cols="12" md="5" lg="4" class="pa-0 d-flex justify-center align-center">
          <div class="boxOtherImg"><div class="otherImg"></div></div>
        </v-col>
        <v-col cols="12" md="7" lg="8" class="d-flex justify-center align-center">
          <div class="otherPad">
            <h2 class="subTitle mb-2">{{ $t('otherPump.title') }}</h2>
            <p v-html="$t('otherPump.txt')"></p>
            <router-link class="button mt-4" to="/contact" ><p class="buttonText" v-html="$t('otherPump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
      <v-row class="pb-16">
        <v-col cols="12" class="pa-0 d-flex justify-center">
          <v-col v-for="(item, i) in Pumps" :key="i" class="">
            <v-container class="pumpItem pa-">          
              <img class="pumpImg" :src="item.img"/>
            </v-container>
          </v-col>
        </v-col>
      </v-row>
    </section>
    
    <!--controller-->
    <section>
      <v-row class="gridpad3 d-flex flex-wrap-reverse justify-center">
        <v-col cols="12" lg="6">
          <div class="controllerPad">
            <h2 class="subTitle mb-2">{{ $t('controller.title') }}</h2>
            <p v-html="$t('controller.txt1')"></p>
            <p v-html="$t('controller.txt2')"></p>
            <router-link class="button mt-4" to="/contact" ><p class="buttonText" v-html="$t('controller.btn')"></p></router-link>
          </div>
        </v-col>
        <v-col cols="12" lg="6" >
          <v-col cols="12" class="py-0 d-flex justify-center"><div class="controllerImg1 border1"></div></v-col>
          <v-col cols="12" class=" hideMController"><div class="controllerImg2"></div></v-col>
          <v-col cols="12" class=" hideController d-flex  justify-center"><div class="controllerImg2"></div></v-col>
        </v-col>
      </v-row>
    </section>

   <!--Distribution-->
   <distributors/>

  </div>
</template>

<style scoped>
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .subtitle {line-height: 30px;}
  .gridpad {padding-bottom: 50px;}
  .gridpad2 {padding: 30px 100px 30px 100px;}
  .gridpad3 {padding: 0px 100px 0px 100px;}
  .topBanner {height: 450px; background-image: url('../../../public/Resources/images/Banner/bannerResidentialPump.jpg'); background-position: center; background-position-y: 100%; background-repeat: no-repeat; background-size: cover;}
  .icon {width: 50px; height: 50px; margin-bottom: -10px;}
  .txtAlign {text-align:center;}
  .hideController { visibility: hidden; height:0px;}
  .hideMController { visibility: visible;}
  /*#region Intro*/ 
  .introBox {text-align: center; padding: 44px 0px 32px 0px;}
  .introTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}
  /*#endregion*/ 
  /*#region SubmersiblePump*/ 
  .subImg {
    height:650px; width:100%; 
    background-image: url("../../../public/Resources/images/Products/summersiblePump.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;}
  .subBox { padding: 44px 0px 32px 0px;}
  .subPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .subTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/ 

  /*#region residentialPump*/ 
  .resImg {background-image: url("../../../public/Resources/images/Products/MaisonProfilPumpResidential.jpg");height:650px; width:100%; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .amstrong1 {background-image: url("../../../public/Resources/images/Products/Amstrong-Residentiel.jpg");height:160px; width:212px; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .amstrong2 {background-image: url("../../../public/Resources/images/Products/Amstrong-Residentiel2.jpg");height:160px; width:212px; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .resPump1 {background-image: url("../../../public/Resources/images/Products/eau_PumpJ5S.jpg");height:160px; width:212px; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .resPump2 {background-image: url("../../../public/Resources/images/Products/eau_PumpIrrGator.jpg");height:160px; width:212px; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .resPump3 {background-image: url("../../../public/Resources/images/Products/eauPumpReservoirs.jpg");height:160px; width:212px; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .resPad {text-align: justify; padding: 150px 150px 0px 0px;}
  .resTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/ 

  /*#region otherPump*/ 
  .otherImg {
    margin: -90px 0px 0px 0px;
    height:635px; width:478px; 
    background-image: url("../../../public/Resources/images/Products/Control-Pump-waterite-Gray1a.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .boxOtherImg {height: 390px; overflow: hidden;}
  .otherBox { padding: 44px 0px 32px 0px;}
  .otherPad {text-align: justify; padding: 0px 100px 0px 0px;}
  .otherTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .pumpItem {
    height: 170px;  width: 200px; 
    position: relative; display: block; 
    margin: auto auto !important;}
  .pumpImg {height: 170px;  width: 200px; }
  /*#endregion*/ 

  /*#region controller*/ 
  .hideMController { padding-top: 32px ;}
  .controllerPad {text-align: justify; padding: 30px 0px 0px 0px}
  .controllerImg1 {
    
    height:300px; width:100%; 
    background-image: url("../../../public/Resources/images/Products/AquavarSolo-images.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:100%;
  }
  .controllerImg2 {
    height:475px; 
    background-image: url("../../../public/Resources/images/Products/AquavarSolo-GoudspumpsV2.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  /*#endregion*/ 

  @media handheld, only screen and (max-width: 600px) {
    .controllerImg1 {
    height:180px; width:100%; 
    background-size:100%;
  }
  }

  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 0px 25px 50px 25px;}
    .gridpad2 {padding: 0px 25px 60px 25px;}
    .gridpad3 {padding: 0px 25px 30px 25px;}
    .subPad {text-align: justify; padding: 0px 25px 0px 25px;}
    .otherPad {padding: 0px 30px 0px 30px !important;}
    .txtAlign {text-align:justify;}
    .resPumpModelPad { margin: 0px; padding: 0px 0px 0px 0px;}
    .otherImg {
      margin: -45px 0px 0px 0px;
      height:350px; width:229px; 
      background-position: center; 
      background-repeat: no-repeat; 
      background-size:cover;
    }
    .boxOtherImg {height: 225px; overflow: hidden;}

  }

  @media handheld, only screen and (max-width: 1279px) {
    .introBox {padding: 44px 0px 0px 0px;}
    .subImg {
      height:350px; width:75%; 
      background-position: center; 
      background-position-y: 95%;}
    .resImg {height:250px; width:100%; background-position: bottom; background-size:100%;}
    .resPump1 {height:128px; width:169px; }
    .resPump2 {height:128px; width:169px; }
    .resPump3 {height:128px; width:169px; }
    .resPad {padding: 0px 0px;}
    .controllerImg2 {height:150px; width:150px;}
    .hideController { visibility: visible; height: 150px; padding-top: 0px; margin-top:-25px}
    .hideMController { visibility: hidden; height: 0px ;}
    .controllerPad {text-align: justify; padding: 0px 0px 0px 0px}
  }
  @media handheld, only screen and (max-width: 1378px) {
  }
</style>

<script>
    import pageTranslations from '../../../public/Resources/i18N/residentialWaterPump.json'
    import distributors from '../../components/distributors.vue';

    export default {
    name: 'artesianWells',
    data: () => ({
      Pumps: [
      {
          img:'Resources/images/Products/Sumppump.jpg'
        },
        {
          img:'Resources/images/Products/eau_Pump3885-residentiel-Goudspumps.jpg'
        },
        {
          img:'Resources/images/Products/eau_Pump3886-residentiel-Goudspumps.jpg'
        },
        {
          img:'Resources/images/Products/eau_Pump3887-residentiel-Goudspumps.jpg'
        },
        {
          img:'Resources/images/Products/eau_Pump3871-residentiel-Goudspumps.jpg'
        }
      ]
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    components: {
      distributors
    },
  }
</script>