<template>
  <div >
    <!--Banner-->
    <section>
      <div class="topBanner"></div>
    </section>

    <!--Intro-->
    <section class="gridpad">
      <v-row>
        <v-col cols="12" class=" ">
          <div class="introBox">
            <img class="icon" src="/Resources/images/icon/pompe-industrielle_hover.png"/>
            <h1 class="introTitle">{{ $t('intro.title') }}</h1>
            <p class="txtAlign" v-html="$t('intro.txt')"></p>
          </div>
        </v-col>
      </v-row>
    </section>

    <!--subPump-->
    <section class="v-tierce2">
      <v-row class="">
        <v-col cols="12" lg="6" class="pa-0">
          <div class="subImg"></div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j">
          <v-col cols="12">
            <div class="subPad">
            <h2 class="subTitle uppercase mb-2">{{ $t('subPump.title') }}</h2>
            <p v-html="$t('subPump.txt')"></p>
          </div>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="8" sm="9" class="d-flex justify-center justify-sm-start align-start">
                <router-link class="button mt-4" to="/contact" ><p class="buttonText" v-html="$t('subPump.btn')"></p></router-link>
              </v-col>
              <v-col cols="12" lg="4" sm="3" class="pa-0 d-flex justify-center align-center"><div class="subImg2"></div></v-col>
            </v-row>
          </v-col>
          
        </v-col>
      </v-row>
    </section>
    
    <!--agri-comm-ind Pump-->
    <section>
      <v-row class="d-flex flex-wrap-reverse justify-center">
        <v-col cols="12" lg="6" class="txt-j">
          <v-row class="aciPad">
            <v-col cols="12">
                <h2 class="aciTitle uppercase mb-2">{{ $t('aciPump.title') }}</h2>
                <p v-html="$t('aciPump.txt')"></p>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="4" class="px-0 d-flex justify-end"><div class="amstrong1"></div></v-col>
                <v-col cols="4" class="px-0 d-flex justify-start"><div class="amstrong2"></div></v-col>
                <v-col cols="4" class="px-0 d-flex justify-start"><div class="amstrong3"></div></v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="txt-c">
              <router-link class="button" to="/contact" ><p class="buttonText" v-html="$t('aciPump.btn')"></p></router-link>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" class="">
          <v-row class="my-0 d-flex flex-wrap justify-center justify-center">
            <v-col cols="12" class="pa-0">
              <div class="aciImg"></div>
            </v-col>
           
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-carousel cycle :show-arrows="false" hide-delimiters infinite style="max-height: 200px; padding-top: 20px;">
        <template v-for="(item, index) in Pumps"> 
          <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
            <v-row class="flex-nowrap" style="height:100%">
              <template v-for="(n,i) in columns"> 
                <template v-if="(+index + i) < Pumps.length"> 
                  <v-col :key="i">
                    <v-sheet v-if="(+index + i) < Pumps.length" >
                      <v-row class="" justify="center">
                        <div class="display-3">
                            <!--ici ton image ou information necessaire-->
                            <img class="" style="position: relative; z-index: 1; max-height: 175px; width: auto;" :src="Pumps[index + i].img"/>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-carousel-item>
        </template>
      </v-carousel>
      </v-row>
    </section>
    
    <!--otherPump-->
    <section class="v-tierce2">
      <v-row class="d-flex gridpad4">
        <v-col cols="12" lg="6" class="align-self-center">
          <v-row>
            <v-col cols="4" class="px-0 d-flex justify-end "><div class="otherImg1"></div></v-col>
            <v-col cols="4" class="px-0 d-flex justify-start"><div class="otherImg3"></div></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j">
          <div class="otherPad">
            <h2 class="subTitle uppercase mb-2">{{ $t('otherPump.title') }}</h2>
            <p v-html="$t('otherPump.txt')"></p>
            <router-link class="button mt-4" to="/contact" ><p class="buttonText" v-html="$t('otherPump.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
    </section>
    
    <!--treatment-->
    <section class="gridpad">
      <v-row>
        <v-col cols="12" class=" ">
          <div class="introBox">
            <img class="icon" src="../../../public/Resources/images/icon/Pieces-et-accessoires_hover.png"/>
            <h1 class="introTitle">{{ $t('treatment.title') }}</h1>
            <p class="txtAlign" v-html="$t('treatment.txt')"></p>
          </div>
        </v-col>
      </v-row>
    </section>

    <!--osReverse-->
    <section class="v-tierce2">
      <v-row class="d-flex">
        <v-col cols="12" lg="6" class="py-0">
          <div class="osImg"></div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j">
          <div class="gridpad3">
            <h2 class="osTitle">{{ $t('osReverse.title') }}</h2>
            <h3 class="osSubtitle">{{ $t('osReverse.subtitle') }}</h3>
            <p class="pb-4" v-html="$t('osReverse.txt')"></p>
            <router-link class="button" to="/contact" ><p class="buttonText" v-html="$t('osReverse.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
    </section>

    <!--softeners-->
    <section class="mt-3">
      <v-row>
        <v-col cols="12" lg="9" class="txt-j">
          <div class="gridpad2">
            <h2 class="softTitle">{{ $t('softeners.title') }}</h2>
            <h3 class="softSubtitle">{{ $t('softeners.subtitle') }}</h3>
            <p v-html="$t('softeners.txt')"></p>
          </div>
        </v-col>
        <v-col cols="12" lg="3" class="pa-0 d-flex justify-end">
          <div class="softImg"></div>
        </v-col>
      </v-row>
    </section>

    <section class="imgSpace">
      <v-row>
        <v-col cols="4" class="pa-0"><div class="softImg2"></div></v-col>
        <v-col cols="4" class="pa-0"><div class="softImg3"></div></v-col>
        <v-col cols="4" class="pa-0"><div class="softImg4"></div></v-col>
      </v-row>
    </section>

   <!--Distribution-->
   <distributors/>

  </div>
</template>

<style scoped>
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .subtitle {line-height: 30px;}
  .gridpad {padding-bottom: 50px;}
  .gridpad2 {padding: 100px;}
  .gridpad3 {padding: 125px 100px 125px 100px;}
  .gridpad4 {padding: 50px 100px 50px 100px;}
  .topBanner {height: 450px; 
    background-image: url('../../../public/Resources/images/Banner/bannerCommercialPump.jpg'); 
    background-position: center; background-position-y: 100%; 
    background-repeat: no-repeat; background-size: cover;
  }
  .icon {width: 50px; height: 50px; margin-bottom: -10px;}
  .txtAlign {text-align:center;}
  .uppercase {text-transform: uppercase;}
  .hideController { visibility: hidden; height:0px;}
  .hideMController { visibility: visible;}
  /*#region Intro*/ 
  .introBox {text-align: center; padding: 44px 150px 32px 150px;}
  .introTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase; padding-bottom:30px;}
  /*#endregion*/ 
  /*#region subPump*/ 
  .subImg {
    height:650px; width:100%; 
    background-image: url("../../../public/Resources/images/Products/Fermeavicole-PompagricoleHD.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .subImg2 {
    height:150px; width:150px; 
    background-image: url("../../../public/Resources/images/Products/eau_PumpSummersible_industrielle-Goudspumps_gray.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .subBox { padding: 44px 0px 32px 0px;}
  .subPad {text-align: justify; padding: 15px 100px 0px 0px;}
  .subTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/ 

  /*#region agri-comm-ind Pump*/ 
  .aciImg {
    height:650px; width:100%; 
    background-image: url("../../../public/Resources/images/Products/Fermebovine-PompagricoleHD.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .amstrong1 {
    height:160px; width:212px; 
    background-image: url("../../../public/Resources/images/Products/Amstrong-Residentiel2.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .amstrong2 {
    height:160px; width:212px; 
    background-image: url("../../../public/Resources/images/Products/Amstrong-Residentiel.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .amstrong3 {
    height:160px; width:212px;
    background-image: url("../../../public/Resources/images/Products/Amstrong-Commercial2.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }

  .aciPad {text-align: justify; padding: 150px 100px 0px 100px;}
  .aciTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .pumpItem {
    height: 170px;  width: 200px; 
    position: relative; display: block; 
    margin: auto auto !important;}
  .pumpImg {height: 170px;  width: 200px; }
  /*#endregion*/ 

  /*#region otherPump*/ 
  .otherImg1 {
    height:160px; width:212px; 
    background-image: url("../../../public/Resources/images/Products/eau_PumpDW_industrielle-GoudspumpsG.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .otherImg2 {
    height:160px; width:212px; 
    background-image: url("../../../public/Resources/images/Products/3888D3_Goudspumps-bleu.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .otherImg3 {
    height:160px; width:212px; 
    background-image: url("../../../public/Resources/images/Products/4SD52_industrielle_Goudspumps-Gray2.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .otherBox { padding: 44px 0px 32px 0px;}
  .otherPad {text-align: justify; padding: 0px 0px 0px 0px;}
  .otherTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .pumpItem {
    height: 170px;  width: 200px; 
    position: relative; display: block; 
    margin: auto auto !important;}
  .pumpImg {height: 170px;  width: 200px; }
  /*#endregion*/ 

  /*#region osReverse*/ 
  .osImg {
    background-image: url("../../../public/Resources/images/Products/Osmose-Industrielle.jpg");
    height:650px; width:100%; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;}
  .osBox { padding: 44px 0px 32px 0px;}
  .osPad {text-align: justify; padding: 60px 0px 0px 0px;}
  .osTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .osSubtitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 16px;}
  /*#endregion*/ 

  /*#region softeners*/ 
  .imgSpace {margin: 100px 0px 0px 0px;}
  .softImg {
    background-image: url("../../../public/Resources/images/Products/VillaSt-Charles_ste_perpetue_Traitement-eau.jpg");
    height:100%; width:100%; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:center;}
  .softImg2 {
    background-image: url("../../../public/Resources/images/Products/FermeDesVoltigeurs_Traitement-eau2.jpg");
    height:600px; width:100%;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:center;}
  .softImg3 {
    background-image: url("../../../public/Resources/images/Products/FermeDesVoltigeurs_Traitement-eau.jpg");
    height:600px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:center;}
  .softImg4 {
    background-image: url("../../../public/Resources/images/Products/FermeDesVoltigeurs_Traitement-eau2a.jpg");
    height:600px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:center;
    }
  .softBox { padding: 44px 0px 32px 0px;}
  .softPad {text-align: justify; padding: 60px 0px 0px 0px;}
  .softTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .softSubtitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 16px;}
  /*#endregion*/ 

  @media handheld, only screen and (max-width: 600px) {}

  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 0px 25px 50px 25px;}
    .gridpad2 {padding: 0px 25px 60px 25px;}
    .gridpad3 {padding: 0px 25px 0px 25px;}
    .gridpad4 {padding: 0px 25px 15px 25px;}
    .aciPad {padding: 0px 25px 15px 25px !important;}
    .subPad { padding: 0px 25px 15px 25px !important;}
    .txtAlign {text-align:justify;}
    .aciPumpModelPad { margin: 0px; padding: 0px 0px 0px 0px;}
    .pumpItem {
      height: 140px;  width: 170px; 
      position: relative; display: block; 
      margin: auto auto !important;}
    .pumpImg {height: 140px;  width: 170px; }
      
  }

  @media handheld, only screen and (max-width: 1279px) {
    .introBox {padding: 44px 0px 0px 0px;}
    .aciPad {padding: 15px 100px 15px 100px;}
    .subPad {padding: 15px 100px 15px 100px;}
    .subImg {
      height:350px; width:100%; 
      background-position: center; 
      background-position-y: 95%;}
    .aciImg {
      height:250px; width:100%; }
    .osImg {
      height:250px; width:100%; }
    .softImg {
      background-image: url("../../../public/Resources/images/Products/FermeDesVoltigeurs_Traitement-eau2a.jpg");
      background-color: rgb(151,153,150);
      height:250px; width:100%;
      background-position: center;
      background-position-y: 15%;  }
      .softImg2 {
      height:0px; width:100%;
      background-position: center;
      background-position-y: 0%;
      background-position-x: 65%;  
      visibility:hidden;}
      .softImg3 {
      height:0px; width:100%;
      background-position: center;
      background-position-y: 15%;  
      visibility:hidden;}
      .softImg4 {
      height:0px; width:100%;
      background-position: center;
      background-position-y: 15%;  
      visibility:hidden;}
    .hideController { visibility: visible; height: 150px;}
    .hideMController { visibility: hidden; height: 0px ;}
  }
  @media handheld, only screen and (max-width: 1378px) {
  }
</style>

<script>
    import pageTranslations from '../../../public/Resources/i18N/agriculturalCommercialAndIndustrialPumps.json'
    import distributors from '../../components/distributors.vue';

    export default {
    name: 'artesianWells',
    data: () => ({
      Pumps: [
      {
          img:'Resources/images/Products/eau_Pumpe-e-SV-GWT-serie_industrielle-Goudspumps.jpg'
        },
        {
          img:'Resources/images/Products/eau_PumpGP3656-S_industrielle-Goudspumps.jpg'
        },
        {
          img:'Resources/images/Products/eau_PumpICS_industrielle-Goudspumps.jpg'
        },
        {
          img:'Resources/images/Products/eau_PumpNPE_industrielle-Goudspumps.jpg'
        },
        {
          img:'Resources/images/Products/eau_Pumpe-sv1_industrielle-Goudspumps.jpg'
        },
        {
          img:'Resources/images/Products/eau_PumpGB_industrielle-Goudspumps.jpg'
        },
        {
          img:'Resources/images/Products/eauPumpReservoirs.jpg'
        }
      ],
      columns: 4,
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    created() {
      window.addEventListener("resize", this.changeColumns);
    },
    destroyed() {
      window.removeEventListener("resize", this.changeColumns);
    },
    methods:{
      changeColumns() {
        let width = window.innerWidth;
        if (width > 1800) { this.columns = 4; }
        else if (width > 1200) { this.columns = 3; }
        else if (width > 800) { this.columns = 2; } 
        else if (width < 800) { this.columns = 1; } 
      }
    },
    components: {
      distributors
    },
  }
</script>