<template>
    <div>
        <div class="d-none d-md-flex">
            <div class="header">
                <v-row class="d-flex align-center">
                    <v-col style="max-height: 20px;" cols="12">
                        <a class="F-IE" href="https://www.facebook.com/BissonService" target="_Blank"><div class="smIcon"></div></a>
                        <router-link class="linkContact F-IE" to="/contact">{{ $t('menu.contact') }}</router-link>
                        <a class="F-IE" style="padding: 5px 10px 0px 0px; color: rgb(var(--primary));">924, rue Saint Pierre, Drummondville QC J2C 3Y2</a>     
                    </v-col>
                    <v-col class="widthImg">
                        <router-link to="/"><img @click="home" class="logoImg" src="/Resources/images/logo/logo.png" /></router-link>
                    </v-col>
                    <v-col>
                        <router-link class="menu F-IE" to="/armstrong" v-html="$t('menu.Armstrong')"></router-link>
                        <!--<a class="menu F-IE" v-if="this.$i18n.locale != 'en'" @click="switchLanguage()">EN</a><a class="menu F-IE" v-if="this.$i18n.locale != 'fr'" @click="switchLanguage()">FR</a>-->
                        <router-link class="menu F-IE" to="/laveuse_haute_pression" v-html="$t('menu.highPressureWasher')"></router-link>
                        <router-link class="menu F-IE" to="/filtre_a_eau">{{ $t('menu.treatment') }}</router-link>  
                        <router-link class="menu F-IE" to="/pompes">{{ $t('menu.pump') }}</router-link>
                        <!--
                        <div class="dropdown F-IE">
                            <a class="menu F-IE">{{ $t('menu.pump') }}</a>
                            <div>
                                <div class="dropdown-content">
                                    <router-link to="/pompes#turbine" v-html="$t('menu.pump1')"></router-link>
                                    <router-link to="/pompes#Sump" v-html="$t('menu.pump2')"></router-link>
                                    <router-link to="/pompes#wellPump" v-html="$t('menu.pump3')"></router-link>
                                    <router-link to="/pompes#sewer" v-html="$t('menu.pump4')"></router-link>
                                    <router-link to="/pompes" v-html="$t('menu.pump5')"></router-link>
                                </div>
                            </div>
                        </div>-->
                        <div class="dropdown F-IE">
                            <a class="menu F-IE">{{ $t('menu.services') }}</a>
                            <div class="dropdown-content"> <!--v-html bonne facon de faire-->
                                <router-link to="/services#emergency" v-html="$t('menu.services1')"></router-link>
                                <router-link to="/analyseEau" v-html="$t('menu.waterAnalysis')"></router-link>
                                <router-link to="/services#repairPump" v-html="$t('menu.services2')"></router-link>
                                <router-link to="/services#repairEngine" v-html="$t('menu.services3')"></router-link>
                                <router-link to="/services#artesianWell" v-html="$t('menu.services4')"></router-link>
                                <router-link to="/services#pressureController" v-html="$t('menu.services5')"></router-link>                                
                            </div>
                        </div>
                        <router-link class="menu F-IE" to="/produits">{{ $t('menu.products') }}</router-link>
                        <router-link class="menu F-IE" to="/experts">{{ $t('menu.experts') }}</router-link>    
                    </v-col>
                </v-row>
            </div>
        </div>

        <!--Mobile menu-->
        <div class="d-flex d-md-none">
            <v-row no-gutters>
                <v-app-bar class="menuM" style="overflow-x: hidden;">
                    <img class="" style="width:35px; height:35px; position: absolute; top:10px; left:10px;" @click.stop="this.drawer = !this.drawer" src="/Resources/images/icon/menu.svg"/>
                    <v-col class="d-flex align-center justify-center">
                        <v-row>
                            <v-col cols="12" class="txt-c "><a class="f-primary fw-700" href="tel:8194727904">819 472-7904</a></v-col>
                            <v-col cols="12" class="txt-c"><router-link to="/"><img style="height: 60px; width: auto;" src="/Resources/images/logo/logoFooter.png" /></router-link></v-col>
                        </v-row>
                        
                        
                    </v-col>
                </v-app-bar>

                <v-navigation-drawer class="drawer" v-model="this.drawer" location="top" style="">
                    <v-list>
                        <v-list-item class="pa-0 ma-0"><router-link to="/produits"><h2 class="menu">{{ $t('menu.products') }}</h2></router-link></v-list-item>
                        <v-list-item class="pa-0 ma-0"><router-link to="/pompes"><h2 class="menu">{{ $t('menu.pump') }}</h2></router-link></v-list-item>
                        <!--
                        <v-list-item class="pa-0 ma-0">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-title class="pl-5 pr-0">
                                        <v-row class="ma-0">
                                            <v-col cols="10" class="d-flex align-center justify-start"><router-link to="/produits"><h2 class="menu px-0 py-2 ma-0">{{ $t('menu.products') }}</h2></router-link></v-col>
                                            <v-col cols="2" class="d-flex align-center justify-end"><div style="height:35px;"><img class="" style="width:100% ; height:100%;" src="/Resources/images/icon/expand_more_black_24dp.svg"/></div></v-col>
                                        </v-row>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text><router-link to="/pompes" class="f-primary" v-html="$t('menu.pump1')"></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/pompes" class="f-primary" v-html="$t('menu.pump2')"></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/pompes" class="f-primary" v-html="$t('menu.pump3')"></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/pompes" class="f-primary" v-html="$t('menu.pump4')"></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/pompes" class="f-primary" v-html="$t('menu.pump5')"></router-link></v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-list-item>-->
                        <v-list-item class="pa-0 ma-0"> 
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-title class="pl-5 pr-0">
                                        <v-row class="ma-0">
                                            <v-col cols="10" class="d-flex align-center justify-start"><h2 class="menu px-0 py-2 ma-0">{{ $t('menu.services') }}</h2></v-col>
                                            <v-col cols="2" class="d-flex align-center justify-end"><div style="height:35px;"><img class="" style="width:100% ; height:100%;" src="/Resources/images/icon/expand_more_black_24dp.svg"/></div></v-col>
                                        </v-row>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text><router-link to="/services" class="f-primary" v-html="$t('menu.services1')"></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/analyseEau" class="f-primary" v-html="$t('menu.waterAnalysis')"></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/services" class="f-primary" v-html="$t('menu.services2')"></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/services" class="f-primary" v-html="$t('menu.services3')"></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/services" class="f-primary" v-html="$t('menu.services4')"></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/services" class="f-primary" v-html="$t('menu.services5')"></router-link></v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0"><router-link to="/filtre_a_eau"><h2 class="menu">{{ $t('menu.treatment') }}</h2></router-link></v-list-item>
                        <v-list-item class="pa-0 ma-0"><router-link to="/laveuse_haute_pression"><h2 class="menu" v-html="$t('menu.highPressureWasher')"></h2></router-link></v-list-item>
                        <v-list-item class="pa-0 ma-0"><router-link class="menu" to="/armstrong" v-html="$t('menu.Armstrong')"></router-link></v-list-item>
                        <v-list-item class="pa-0 ma-0"><router-link to="/contact"><h2 class="menu">{{ $t('menu.contact') }}</h2></router-link></v-list-item>
                    </v-list>
                </v-navigation-drawer>         
            </v-row>
        </div>
    </div>
    
</template>
<style scoped>
    .mHide {visibility: visible;}
    .menu { text-transform:uppercase; color: rgb(var(--secondary)); font-weight: 700; font-size: small;}
    .menu:hover {color: rgb(var(--primary)); text-decoration: none; cursor: pointer;} 
    .menuM { padding: 15px 10px; width: 100%; }
    .langBtn {text-transform:uppercase; color: black; font-weight: 700; font-size: small;}
    .langBtn:hover {color: rgb(var(--secondary)); text-decoration: none; cursor: pointer;} 
    .v-col {padding: 0px; }
    .smIcon{height: 25px; width: 25px; margin: 0px 0px; background-image: url(./../../public/Resources/images/sm/facebook-25x25.svg);}
    .smIcon:hover{background-image: url(./../../public/Resources/images/sm/facebook-25x25-Hover.svg);}
    .dropdown { position: relative; float:inline-end; }
    .dropdown-content { display: none; position: absolute; z-index: 100; background-color:  rgb(var(--tierce)); box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); z-index: 1; margin: 50px 0px 0px 0px; right: 0px;}
    .dropdown-content a { color: rgb(var(--primary)); padding: 12px 16px; text-decoration: none; display: block; text-align: right; }
    .dropdown-content a:hover {background-color: rgb(var(--secondary)); color: rgb(var(--tierce));}
    .dropdown:hover .dropdown-content {display: block;}
    .drawer { padding-top: 20px !important; width:75% !important; height: 100% !important;}
    .logoImg { height: auto; width: 100%;}

    .widthImg { max-width: 200px; border: 1px solid rgba(255,255,255,0); }
    .linkContact {padding: 5px 10px 0px 0px; color: rgb(var(--secondary));}
    .linkContact:hover {text-decoration: underline;}
    @media handheld, only screen and (max-width: 450px) {
        .drawer { width:100% !important;}
    }
    @media handheld, only screen and (max-width: 600px) {

    }

    @media handheld, only screen and (max-width: 960px) {
        .mHide {visibility: hidden;}
        .menu {font-size: small !important;}
    }

    @media handheld, only screen and (max-width: 1279px) {
        p {font-size: 14px;}
        .widthImg { max-width: 125px; }
        .menu {font-size: 11px;}

    }
    @media handheld, only screen and (max-width: 1378px) {

    }
</style>
<script>
import translations from '../../public/Resources/i18N/global.json';
export default {
    name: 'Header',
    data: () => ({
        drawer: false,
    }),
    mounted() {
        this.$i18n.mergeLocaleMessage('fr', translations.fr);
        this.$i18n.mergeLocaleMessage('en', translations.en);
    },
    methods: {
        home: function(){ this.$router.push('/'); },
        switchLanguage: function () {
         this.$i18n.locale = this.currentLang() === 'en' ? 'fr' : 'en';     
        },
        currentLang() {
            return this.$i18n.locale;
        },
    },
    component: {}
}
</script>