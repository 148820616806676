<template>
    <section>
      <v-row>
        <v-col cols="12" class="py-0">
          <h2 class="titleDist">{{ $t('dist.title') }}</h2>
        </v-col>
      </v-row>
      <v-carousel cycle :show-arrows="false" hide-delimiters infinite style="max-height: 250px; padding-top: 20px;">
        <template v-for="(item, index) in Dist"> 
          <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
            <v-row class="flex-nowrap" style="height:100%">
              <template v-for="(n,i) in columns"> 
                <template v-if="(+index + i) < Dist.length"> 
                  <v-col :key="i">
                    <v-sheet v-if="(+index + i) < Dist.length"  height="100%" >
                      <v-row class="" justify="center">
                        <div class="display-3">
                            <!--ici ton image ou information necessaire-->
                          <a :href="item.webLink" target="_blank">
                            <img class="itemHover" style="position: absolute; z-index: 2; " :src="Dist[index + i].hover"/>
                            <img class="" style="position: relative; z-index: 1; " :src="Dist[index + i].img"/>
                          </a>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-carousel-item>
        </template>
      </v-carousel>
    </section>
</template>
<style scoped>
    .titleDist {text-align: center; font-size: 26px; font-weight: 700; background-color: rgb(var(--tierce2)); color: rgb(var(--secondary)); padding:60px 0px 60px 0px;}
    .itemHover {opacity:0;}
    .itemHover:hover {opacity:1;}

    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1264px) {}  
</style>
<script>
    import pageTranslations from '../../public/Resources/i18N/distributors.json';

  export default {
    name: 'Distributors',
    data: () => ({
      Dist: [
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeur_armstrong_petitV2.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeur_armstrong_petit0ver.jpg',
          webLink:'http://armstrongfluidtechnology.com/en'
        },
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeur_ProminentPetitV3.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeur_ProminentPetitV3-over.jpg',
          webLink:'https://www.prominent.ca/en/'
        },
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeur_Goulds-pumps_petitNEW.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeur_Goulds-pumps_petitNEW-over.jpg',
          webLink:'https://www.xylem.com/en-us/brands/goulds-water-technology/'
        },
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeur_TrojanUVmaxHD2V2.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeur_TrojanUVmaxHD2V2-over.jpg',
          webLink:'https://www.trojanuv.com/fr/'
        },
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeur_selWarwickPetitV2.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeur_selWarwickPetitV20ver.jpg',
          webLink:'https://www.selwarwick.com/produits/24/sel-de-mer-naturel-magic'
        },
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeur_WaterRitePetitV2.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeur_WaterRitePetitV2_over.jpg',
          webLink:'https://www.waterite.com/'
        },
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeurViquaPetit.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurViquaPetit_over.jpg',
          webLink:''
        },
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeurCorro_protecPetit.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurCorro_protecPetit_over.jpg',
          webLink:'https://www.corroprotec.com/fr/'
        },
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeurlittleGiantPetit.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurlittleGiantPetit_over.jpg',
          webLink:'https://www.littlegiant.com/'
        },
        {
          img:'Resources/images/Distributors/admin-ajax_distributeurFranklinPetit.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurFranklinPetit-over.jpg',
          webLink:'https://franklin-electric.com/'
        },
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeurSanifloPetit.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurSanifloPetit-over.jpg',
          webLink:'https://www.saniflo.ca/fr/'
        },
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeurMaximotion.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurMaximotion_over.jpg',
          webLink:''
        },
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeurMarathon-motors.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurMarathon-motors_over.jpg',
          webLink:''
        },
        {
          img:'/Resources/images/Distributors/admin-ajax_distributeurOmniDrive.jpg',
          hover:'Resources/images/Distributors/admin-ajax_distributeurOmniDrive_over.jpg',
          webLink:'https://www.uem.ca/omnidrive/products'
        }
      ],
      columns: 3,
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
      this.changeColumns();

    },
    created() {
      window.addEventListener("resize", this.changeColumns);
    },
    destroyed() {
      window.removeEventListener("resize", this.changeColumns);
    },
    computed: {

    },
    methods:{
      changeColumns() {
        let width = window.innerWidth;
        if (width > 1800) { this.columns = 4; }
        else if (width > 1200) { this.columns = 3; }
        else if (width > 800) { this.columns = 2; } 
        else if (width < 800) { this.columns = 1; } 
      }
    },
    component: {

    }
    
  }
</script>