<template>
  <div >

    <!--Banner-->
    <section>
      <div class="topBanner"></div>
    </section>

    <!--Intro-->
    <section class="gridpad">
      <v-row>
        <v-col cols="12">
          <div class="txt-c mt-11">
            <img class="icon" src="/Resources/images/icon/reparation-moteur-electrique.png"/>
            <h1 class="titleInfo gridpadSub">{{ $t('intro.title') }}</h1>
            <p class="inlineTxt" v-html="$t('intro.text')"></p>
            <router-link class="link inlineTxt" to="/reparation_moteurs_electriques">{{ $t('intro.link') }}</router-link>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <router-link class="button" to="/contact" ><p class="buttonText" v-html="$t('intro.btn')"></p></router-link>
        </v-col>
      </v-row>
    </section>

    <!--Motors-->
    <section class="gridpad">
      <v-row class="mb-6 " >
        <v-col v-for="(item, i) in Motors" :key="i">
          <v-container class="motorItem pa-0 d-flex justify-start">
            <div>              
                <img class="motorImg" :src="item.img"/>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </section>

    <!--Distribution-->
    <distributors/>
    
  </div>
</template>

<style scoped>
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .subtitle {line-height: 30px;}
  .gridpad {padding-bottom:50px;}
  .sub {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;text-transform: uppercase;}
  .topBanner {height: 450px; background-image: url('../../../public/Resources/images/Banner/bannerElectricMotors-2.jpg'); background-position: center; background-position-y: 100%; background-repeat: no-repeat; background-size: cover;}
  .link {font-size: 18px; color: rgb(var(--secondary));}
  .link:hover {color: rgb(var(--primary));}
  .inlineTxt {display: inline;}
  /*#region Intro*/ 
  .titleInfo {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}
  /*#endregion*/
  /*#region Motors*/ 
  .motorItem {height: 170px;  width: 200px; position: relative; display: block; margin: auto auto !important;}
  .motorImg {height: 170px;  width: 200px; }
  /*#endregion*/
  @media handheld, only screen and (max-width: 600px) {
  }

  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 25px 25px 50px 25px;}
  }

  @media handheld, only screen and (max-width: 1279px) {
  }
  @media handheld, only screen and (max-width: 1378px) {

  }
</style>

<script>
    import pageTranslations from '../../../public/Resources/i18N/electricMotors.json';
    import distributors from '../../components/distributors.vue';
    export default {
    name: 'artesianWells',
    data: () => ({
      Motors: [
        {
          img:'Resources/images/Products/electricMotor1.jpg'
        },
        {
          img:'Resources/images/Products/electricMotor2.jpg'
        },
        {
          img:'Resources/images/Products/electricMotor3.jpg'
        },
        {
          img:'Resources/images/Products/electricMotor4.jpg'
        },
        {
          img:'Resources/images/Products/electricMotor5.jpg'
        },
        {
          img:'Resources/images/Products/electricMotor6.jpg'
        }
      ]
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    components: {
      distributors
    },
  }
</script>