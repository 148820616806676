<template>
  <div >

    <!--Banner-->
    <section>
      <div class="topBanner"></div>
    </section>

    <!--Intro-->
    <section class="gridpad">
      <v-row>
        <v-col cols="12" class=" ">
          <div class="introBox">
            <img class="icon" src="../../../public/Resources/images/icon/Pieces-et-accessoires_hover.png"/>
            <h1 class="titleIntro">{{ $t('intro.title') }}</h1>
            <p class="txtAlign" v-html="$t('intro.txt')"></p>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--filter-->
    <section id="filter" class="">
      <v-row class="">
        <v-col cols="12" lg="8" class="txt-j section-c">
          <div class="filterPad">
            <h2 class="filterTitle mb-2">{{ $t('Filter.title') }}</h2>
            <p v-html="$t('Filter.subTxt')"></p>
            <p v-html="$t('Filter.subTxt2')"></p>
            <ul class="ulpad">
              <li v-html="$t('Filter.ul1')"></li>
              <li v-html="$t('Filter.ul2')"></li>
              <li v-html="$t('Filter.ul3')"></li>
              <li v-html="$t('Filter.ul4')"></li>
              <li v-html="$t('Filter.ul5')"></li>
              <li v-html="$t('Filter.ul6')"></li>
              <li v-html="$t('Filter.ul7')"></li>
              <li v-html="$t('Filter.ul8')"></li>
            </ul>
            <p v-html="$t('Filter.bottomTxt')"></p>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--Softeners-->
    <section class="">
      <v-row class="d-flex">
        <v-col cols="12" lg="6" class="mb-4">
          <div class="softImg"></div>
        </v-col>

        <v-col cols="12" lg="6" class="txt-j">
          <div class="softenersPad">
            <h2 class="softTitle">{{ $t('softeners.title') }}</h2>
            <p class="mt-10" v-html="$t('softeners.txt')"></p>
            <p class="mt-5" v-html="$t('softeners.txt2')"></p>
            <router-link class="button mt-5" to="/contact" ><p class="buttonText" v-html="$t('softeners.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="imgSpace">
      <v-row>
        <v-col cols="12" md="4" class="pa-0"><div class="softImg2"></div></v-col>
        <v-col cols="12" md="4" class="pa-0"><div class="softImg3"></div></v-col>
        <v-col cols="12" md="4" class="pa-0"><div class="softImg4"></div></v-col>
      </v-row>
    </section>
        <!--treatmentWater-->
    <section id="treatmentWater">
      <v-row class="">
        <v-col cols="12" lg="8" class="txt-j section-c">
          <div>
            <h2 class="treatmentWaterTitle">{{ $t('treatmentWater.title') }}</h2>
            <p class="treatmentWaterPad" v-html="$t('treatmentWater.txt')"></p>
            <p class="treatmentWaterPad" v-html="$t('treatmentWater.txt2')"></p>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--Treatment-->
    <section id="uv" class="">
      <v-row class="d-flex gridpad4">
        <v-col cols="12" lg="6" class="py-0">
          <div class="uvImg"></div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j">
          <div class="uvPad">
            <h2 class="uvTitle">{{ $t('uv.title') }}</h2>
            <p v-html="$t('uv.txt')"></p>
            <ol class="ulpad">
              <li v-html="$t('uv.ol1')"></li>
              <li v-html="$t('uv.ol2')"></li>
              <li v-html="$t('uv.ol3')"></li>
              <li v-html="$t('uv.ol4')"></li>
            </ol>
            <p class="mt-10" v-html="$t('uv.bottomTxt')"></p>
            <p class="mt-5" v-html="$t('uv.bottomTxt2')"></p>
            <p class="mt-5" v-html="$t('uv.bottomTxt3')"></p>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--Section4 ContactUs-->
    <contactUs/>
    <!--filter-->
    <section id="treatmentSulfur" class="v-primary-light-1">
      <v-row class="">
        <v-col cols="12" lg="8" class="txt-j section-c">
          <div class="treatmentSulfurPad">
            <h2 class="treatmentSulfurTitle mb-2">{{ $t('treatmentSulfur.title') }}</h2>
            <ol class="ulpad">
              <li v-html="$t('treatmentSulfur.ol1')"></li>
              <li v-html="$t('treatmentSulfur.ol2')"></li>
              <li v-html="$t('treatmentSulfur.ol3')"></li>
              <li v-html="$t('treatmentSulfur.ol4')"></li>
              <li v-html="$t('treatmentSulfur.ol5')"></li>
              <li v-html="$t('treatmentSulfur.ol6')"></li>
            </ol>
            <p v-html="$t('treatmentSulfur.bottomTxt')"></p>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--Osmose-->
    <section id="Osmose" class="gridpad2">
      <v-row class="d-flex flex-wrap-reverse justify-center">
        <v-col cols="12" lg="6">
          <div class="osLeftBox">
            <h2 class="osTitle">{{ $t('osmose.title') }}</h2>
            <p class="mt-4" v-html="$t('osmose.txt')"></p>
            <ol class="ulpad">
              <li v-html="$t('osmose.ol1')"></li>
              <li v-html="$t('osmose.ol2')"></li>
              <li v-html="$t('osmose.ol3')"></li>
              <li v-html="$t('osmose.ol4')"></li>
              <li v-html="$t('osmose.ol5')"></li>
              <li v-html="$t('osmose.ol6')"></li>
              <li v-html="$t('osmose.ol7')"></li>
              <li v-html="$t('osmose.ol8')"></li>
            </ol>
            <p class="mt-4" v-html="$t('osmose.bottomTxt')"></p>
          </div>
        </v-col>

        <v-col cols="12" lg="6">
          <v-row class="d-flex ">
            <v-col cols="12">
              <div class="osRightBox">
                <div><img class="osImg" src="/Resources/images/Products/Vectapure360_Blanc.jpg"/></div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <!--Fountain-->
    <section class="mt-8">
      <v-row class="d-flex gridpad3">
        <v-col cols="12" lg="6">
          <div class="fountainImg"></div>
        </v-col>

        <v-col cols="12" lg="6" class="txt-j">
          <div class="fountainPad">
            <h2 class="fountainTitle">{{ $t('fountain.title') }}</h2>
            <h3>{{ $t('fountain.slogan') }}</h3>
            <p v-html="$t('fountain.txt')"></p>
            <router-link class="button mt-4 mb-4" to="/contact" ><p class="buttonText" v-html="$t('fountain.btn')"></p></router-link>
          </div>
        </v-col>
      </v-row>
      <v-row class="v-tierce2">
        <v-col cols="12" class="txt-j pt-4 gridpad3">
          <div class="fountainPad">
            <v-col cols="12"><p v-html="$t('fountain.txt2Title')"></p></v-col>
            <v-row>
              <v-col cols="12" md="6" class=""><p v-html="$t('fountain.txt2A')"></p></v-col>
              <v-col cols="12" md="6" class=""><p v-html="$t('fountain.txt2B')"></p></v-col>   
            </v-row>
          </div>
        </v-col>
      </v-row>
    </section>

  </div>
</template>

<style scoped>
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .subtitle {line-height: 30px;}
  .gridpad {padding-bottom: 50px;}
  .gridpad2 {padding: 0px 100px 100px 100px;}
  .gridpad3 {padding: 0px 100px 45px 100px;}
  .gridpad4 {padding: 0px 100px 0px 100px;}
  .topBanner {
    height: 450px; 
    background-image: url('../../../public/Resources/images/Banner/bannerFilterTraitment.jpg'); 
    background-position: center; 
    background-position-y: 100%; 
    background-repeat: no-repeat; 
    background-size: cover;}
  .icon {width: 50px; height: 50px; margin-bottom: -10px;}
  .iconPdf {width: 30px; height: 30px; margin: 0px 0px -5px 10px;}
  .inlineTxt {display: inline;}
  .txtAlign {text-align:center;}

  /*#region Intro*/ 
  .introBox {text-align: center; padding: 44px 0px 32px 0px;}
  .titleIntro {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}
  /*#endregion*/
  /*#region filter*/
  
      #filter ul li {padding: 10px 0px;}
  .filterBox { padding: 44px 0px 32px 0px;}
  .filterPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .filterTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/
    /*#region treatmentWater*/
  .treatmentWaterBox { padding: 44px 0px 32px 0px;}
  .treatmentWaterPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .treatmentWaterTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px; text-align: center; padding: 20px 0px;}
  /*#endregion*/
  /*#region Osmose*/
  .osLeftBox {text-align: justify;  padding: 0px 0px 0px 0px; }
  .osRightBox {text-align: justify;  padding: 0px 0px 0px 0px;}
  .osTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px; text-transform: uppercase;}
  .osSlogan {color: rgb(var(--secondary)); font-size: 16px;}
  .osSubtitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .osLogo {height:175px; margin-bottom: 32px; float: right;}
  .osImg {height:400px; width: auto; transform: translate(80%,50%);}
  /*#endregion*/ 
  /*#region Softeners*/
  .softImg {background-image: url("../../../public/Resources/images/Products/Fusion2_v2-White.jpg"); height:100%; width:100%; background-position: center center; background-repeat: no-repeat; background-size:contain;}
  .softenersPad {text-align: justify; padding: 30px 0px;}
  .softTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .softImg2 {
    background-image: url("../../../public/Resources/images/Products/FermeDesVoltigeurs_Traitement-eau2.jpg");
    height:600px; width:100%;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:center;}
  .softImg3 {
    background-image: url("../../../public/Resources/images/Products/FermeDesVoltigeurs_Traitement-eau.jpg");
    height:600px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:center;
  }
  .softImg4 {
    background-image: url("../../../public/Resources/images/Products/FermeDesVoltigeurs_Traitement-eau2a.jpg");
    height:600px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:center;
  }
  /*#endregion*/ 
  /*#region Fountain*/
  .fountainImg {background-image: url("../../../public/Resources/images/Products/machineEau.jpg");  height:700px; width:100%; background-position: center center; background-repeat: no-repeat; background-size:contain;}
  .fountainPad {text-align: justify; padding: 30px 0px 0px 0px;}
  .fountainTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/ 
  /*#region UV*/
  .uvBox {padding: 44px 0px 32px 0px; text-align: center;}
  .uvTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .uvImg {background-image: url("../../../public/Resources/images/Products/ULTRAviolet_deux_white.jpg");height:100%; width:100%; background-position: center center; background-repeat: no-repeat; background-size:contain;}
  .uvBox { padding: 44px 0px 32px 0px;}
  .uvPad {text-align: justify; padding: 60px 0px 0px 0px;}
  .uvTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/ 
  /*#region Treatment*/
  .treatmentImg {background-image: url("../../../public/Resources/images/Products/ULTRAviolet_deux.jpg");height:100%; max-height:700px; min-height: 550px; width:100%; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .treatmentBox { padding: 44px 0px 32px 0px;}
  .treatmentPad {text-align: justify; padding: 60px 0px 0px 0px;}
  .treatmentTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/ 
  /*#region treatmentSulfur*/
  
    #treatmentSulfur ol li {padding: 10px 0px;}
  .treatmentSulfurBox { padding: 44px 0px 32px 0px;}
  .treatmentSulfurPad {text-align: justify; padding: 15px 50px 15px 50px;}
  .treatmentSulfurTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  /*#endregion*/
  .ulpad { padding: 0px 40px;}

  @media handheld, only screen and (max-width: 600px) {
    
  .treatmentSulfurPad { padding: 15px; }
  .treatmentWaterPad { padding: 15px; }
  .filterPad { padding: 15px; }
  .ulpad { padding: 0px 10px;}
  }

  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 0px 25px 50px 25px;}
    .gridpad2 {padding: 0px 25px 60px 25px;}
    .gridpad4 {padding: 0px 25px 60px 25px;}
    .gridpad3 {padding: 0px 25px 0px 25px;}
    .txtAlign {text-align:justify;}
    .osLeftBox {transform: translate(0%,0%); padding: 0px;}

  }

  @media handheld, only screen and (max-width: 1279px) {
    .introBox {padding: 44px 0px 0px 0px;}
    .osImg {display: none; height:0px; }
    .osLogo {width:433px; margin-bottom: 0px; height:112px;}
    .softenersPad {text-align: justify; padding: 60px 0px 0px 0px;}
    .softImg {min-height:300px; background-position-y: 33%; background-position: center; background-repeat: no-repeat; background-size:contain;}
    .fountainPad {text-align: justify; padding: 0px 0px 0px 0px;}
    .fountainImg {height:300px; background-position: bottom; background-repeat: no-repeat; background-size:contain;}
    .treatmentPad {text-align: justify; padding: 0px 0px 0px 0px;}
    .softImg2 {height:400px; }
  .softImg3 { height:400px;}
  .softImg4 { height:400px;}
  }
  @media handheld, only screen and (max-width: 1378px) {
  }
</style>

<script>
    import contactUs from '@/components/bannerContactUs.vue';
    import pageTranslations from '../../../public/Resources/i18N/waterFilterAndTreatment.json'
    import distributors from '../../components/distributors.vue';

    export default {
    name: 'artesianWells',
    data: () => ({}),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    components: {
      contactUs,
      distributors
    },
  }
</script>