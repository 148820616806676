<template>
  <div >

    <!--Banner-->
    <expertsBanner/>

    <!--section1 Intro-->
    <section>
      <v-row class="bannerTextPad">
        <v-col cols="12">
          <div class="txt-c ">
            <img class="iconBanner" src="/Resources/images/icon/Pompe-a-eau_hover.png"/>
          </div>
          <h1 class="txt-c bannerTitle">{{ $t('sectionBanner.title') }}</h1>
        </v-col>
      </v-row>
    </section>


     <!--Team-->
    <section class="bgSectionTeam">
      <v-row class="d-flex justify-center">
        <v-col cols="12"  lg="6" class="py-0">
          <div class="bgSectionTeamPhoto1"></div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j sectionTeamTextPad">
          <h2 class="sectionTeamTitle">{{ $t('sectionTeam.title') }}</h2>
          <h3 class="sectionTeamSubtitle pb-30">{{ $t('sectionTeam.slogan') }}</h3>
          <p class="pb-30" v-html=" $t('sectionTeam.text') "></p>
        </v-col>
      </v-row>
    </section>

    <!--History-->
    <section class="gridpad2">
      <v-row class="d-flex ">
        <v-col cols="12" lg="6" class="txt-j">
          <div class="sectionHistoryTextPadLeft">
            <p v-html="$t('sectionHistory.1958')"></p>
            <p v-html="$t('sectionHistory.1973')"></p>
            <p v-html="$t('sectionHistory.1975')"></p>
            <p v-html="$t('sectionHistory.1977')"></p>
            <p v-html="$t('sectionHistory.1980')"></p>
            <p v-html="$t('sectionHistory.1996')"></p>
            <p v-html="$t('sectionHistory.1998')"></p>
            <p v-html="$t('sectionHistory.2001')"></p>
          </div>
        </v-col>

        <v-col cols="12" lg="6" class="txt-j">
          <div class="sectionHistoryTextPadRight">
            <p v-html="$t('sectionHistory.today')"></p>
            <p v-html="$t('sectionHistory.partnership')"></p>
          </div>
        </v-col>
      </v-row>
    </section>

    <!--Carousel Team
    <section class="gridpad2">
      <v-row>
        <h2 class="bannerTitle">{{ $t('carouselTeam.title') }}</h2>
      </v-row>
      <v-row class="mt-20">
        <v-carousel :show-arrows="false" hide-delimiters cycle infinite>
          <v-carousel-item>
            <div>
              <v-row>
                <v-col cols="12" md="6" class="d-flex justify-center">
                  <img style="display: block; margin-left: auto; margin-right: auto;" src="/Resources/images/equipe/solo/antoine.png" />
                </v-col>
                <v-col cols="12" md="6" class="d-flex justify-center align-center">
                  <div class="sizeTextM">
                    <h3 class="sectionTeamTitle">{{ $t('carouselTeam.diapo1Title') }}</h3>
                    <p class="mt-5" style="text-align: justify;" v-html="$t('carouselTeam.diapo1Txt')"></p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div>
              <v-row >
                <v-col cols="12" md="6" class="d-flex justify-center">
                  <img style="display: block; margin-left: auto; margin-right: auto;" src="Resources/images/equipe/solo/chantal.png" />
                </v-col>
                <v-col cols="12" md="6" class="d-flex justify-center align-center">
                  <div class="sizeTextM">
                    <p class="mt-5" style="text-align: justify;" v-html="$t('carouselTeam.diapo2Txt')"></p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-row>
    </section>-->
  </div>
</template>
<style scoped>
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .pb-30 {padding-bottom: 30px;}
  .button {text-transform: uppercase;}
  .gridpad2 {padding: 100px 100px;}

  /*#region section Banner*/ 
  .bannerTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}
  .bannerTextPad {padding:45px 0px 60px 0px;}
  .iconBanner {width: 50px; height: 50px; margin-bottom: -10px;}
  /*#endregion*/ 

  /*#region section Team*/ 
  .sectionTeamTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .sectionTeamSubtitle {color: rgba(var(--menuColor),0.8); font-weight: 500; font-size: 22px;}
  .bgSectionTeam {background-color: rgb(var(--tierce2));}
  .bgSectionTeamPhoto1 {background-image: url("../../public/Resources/images/Home/equipe-bisson2.jpg"); height:100%; width:100%; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .sectionTeamTextPad { padding: 150px 200px 150px 45px;}
  /*#endregion*/ 

  /*#region section History*/ 
  .sectionHistoryTextPadLeft { padding: 0px 15px 0px 0px;}
  .sectionHistoryTextPadRight { padding: 0px 0px 0px 15px;}
  /*#endregion*/ 
  .v-carousel {height: 100% !important;}
  .sizeTextM {width: 75%;}

  @media handheld, only screen and (max-width: 600px) {
    .sizeTextM {width: 100%;}
  }

  @media handheld, only screen and (max-width: 960px) {
    
  }

  @media handheld, only screen and (max-width: 1279px) {
    .gridpad2 {padding: 50px 25px 50px 25px;}
    .bgSectionTeamPhoto1 {height:300px; background-position-y: 33%; background-repeat: no-repeat; background-size:150%;}
    .sectionTeamTextPad {padding: 25px 30px 30px 30px;}
    .sectionHistoryTextPadLeft { padding: 0px 0px 0px 0px;}
  .sectionHistoryTextPadRight { padding: 0px 0px 0px 0px;}

  }
  @media handheld, only screen and (max-width: 1378px) {

  }
</style>

  <script>
      import pageTranslations from '../../public/Resources/i18N/experts.json'
      import expertsBanner from '@/components/banner/expertsBanner.vue'
      import distributors from '@/components/distributors.vue';

    export default {
      name: 'experts',
      data: () => ({}),
      mounted() {
        this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
        this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
  
      },
      components: {
        expertsBanner,
        distributors
      },
    }
  </script>