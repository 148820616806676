<template>
    <section class="mb-4">
        <v-carousel :show-arrows="false" hide-delimiters style="height:600px;" cycle infinite>
            <v-carousel-item src="/Resources/images/Banner/homeDrinkingWater.jpg" cover style="position:relative;">
                <div class="mHide" style="position:absolute; top:50%; left:50%; transform: translate(-50%, -50%);  width:75%">
                    <h2 class="txt">{{ $t('diapo.drinkingWater') }} </h2>        
                </div>

            </v-carousel-item>

            <v-carousel-item src="/Resources/images/Banner/homeTasteSmell.jpg" cover style="position:relative;">
                <div class="mHide" style="position:absolute; top:50%; left:50%; transform: translate(-50%, -50%); width:75%" >
                    <h2 class="txt">{{ $t('diapo.tasteSmell') }} </h2>
                </div>
                <div class="mHide" style="position:absolute; bottom:3%;  width:100%" >
                    <v-col cols="12" class="d-flex justify-center"><router-link to="/analyseEau/#TypeWater"><v-btn class="btn">{{ $t('diapo.tasteSmellBtn') }}</v-btn></router-link></v-col>                  
                </div>
            </v-carousel-item>

            <v-carousel-item src="/Resources/images/Banner/homeControl.jpg" cover style="position:relative;">
                <div class="mHide" style="position:absolute; top:50%; left:50%; transform: translate(-50%, -50%); width:75%" >
                    <h2 class="txt">{{ $t('diapo.control') }} </h2>              
                </div>
                <div class="mHide" style="position:absolute; bottom:3%;  width:100%" >
                    <v-col cols="12" class="d-flex justify-center"><router-link to="/analyseEau"><v-btn class="btn">{{ $t('diapo.controlBtn') }}</v-btn></router-link></v-col>                  
                </div>
            </v-carousel-item>

            <v-carousel-item src="/Resources/images/Banner/homeQuality.jpg" cover style="position:relative;">
                <div class="mHide" style="position:absolute; top:50%; left:50%; transform: translate(-50%, -50%); width:75%" >
                    <h2 class="txt">{{ $t('diapo.quality') }} </h2>             
                </div>
                <div class="mHide" style="position:absolute; bottom:3%;  width:100%" >
                    <v-col cols="12" class="d-flex justify-center"><router-link to="/pompes_agricoles_commerciales_industrielles"><v-btn class="btn">{{ $t('diapo.qualityBtn') }}</v-btn></router-link></v-col>                  
                </div>
            </v-carousel-item>

        </v-carousel>


    </section>
</template>
<style scoped>
    .box {}
    .txt {
        text-align: center ; font-weight: 700; line-height: 5vw; 
        font-size: 4vw !important;  color: rgb(var(--secondary)); 
        text-transform: uppercase !important;  }
    .btn {
        color: rgb(var(--tierce)); 
        background-color: rgb(var(--primary)); 
        font-size: 16px!important;
    }

    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {
        .btn {font-size: 12px !important;
    }
    }
    @media handheld, only screen and (max-width: 1264px) {}  
</style>
<script>
    import pageTranslations from '../../public/Resources/i18N/global.json'

  export default {
    name: 'Diapo',
    data: () => {},
    
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    methods:{

    },
    component: {

    }
    
  }
</script>