<template>
    <div class="topBanner">
      <img src=""/>
    </div>
</template>
<style scoped>
  .topBanner {height: 450px; background-image: url('../../../public/Resources/images/Experts/Banner.jpg'); background-position: center; background-position-y: 100%; background-repeat: no-repeat; background-size: cover;}

  @media handheld, only screen and (max-width: 600px) {}
  @media handheld, only screen and (max-width: 960px) {}
  @media handheld, only screen and (max-width: 1264px) {}  
</style>
<script>
  export default {
    name: 'ExpertsBanner',
    data: () => ({

    }),
    
    mounted() {

    },
    methods:{

    },
    component: {

    }
    
  }
</script>