<template>
  <div >    
        <!--v1.2 Skills-->
    <section style="">
      <services/>
    </section>

    <!--urgence 24h-->
    <section id="emergency" class="v-primary-light-1 gridpad">
      <v-row class="">
        <v-col cols="12">
          <div class="infoTextPad">
            <h2 class="subtitle txt-c" v-html="$t('emergency.title')"></h2>
            <p class="inlineTxt mt-5" v-html="$t('emergency.txt')"></p>
          </div>
        </v-col>
      </v-row>
    </section>
        <!--Section4 ContactUs-->
        <contactUs/>
    <!--entretien et réparation pompe-->
    <section id="repairPump" class="mt-10">
      <v-row class="gridpad">
        <v-col cols="12">
          <div class="infoTextPad">
            <h2 class="subtitle txt-r">{{ $t('pumpRepair.title') }}</h2>
          </div>
        </v-col>
        <v-col cols="12">
          <div>
            <h2 class="fw-700 gridpadSub txt-c mt-10">{{ $t('pumpRepair.subtitle') }}</h2>
            <p class="inlineTxt mt-5" v-html="$t('pumpRepair.text')"></p>
            <p class="txt-c mt-10">{{ $t('pumpRepair.link') }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="6" class="pa-0">
          <div class="imgReparePump1"></div>
        </v-col>
        <v-col cols="6" class="pa-0">
          <div class="imgReparePump2"></div>
        </v-col>
      </v-row>
    </section>
     <!--réparation moteur-->
     <section id="repairEngine" class="gridpad">
      <v-row>
        <v-col cols="12" class=" ">
          <div class=" mt-11">
            <h2 class="subtitle gridpadSub">{{ $t('repareMotor.title') }}</h2>
            <p class="txt-c infoTextPad mt-10" v-html="$t('repareMotor.text')"></p>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="6" class="pa-0">
          <div class="imgmotor1"></div>
        </v-col>
        <v-col cols="6" class="pa-0">
          <div class="imgmotor2"></div>
        </v-col>
      </v-row>
    </section>
    <!--Forage-->
    <section id="artesianWell" class="bgForage">
      <v-row class="d-flex justify-center">
        <v-col cols="12"  lg="6" class="pa-0">
          <div class="imgForage"></div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j">
          <div class="padForage">
            <h2 class="sub">{{ $t('forage.title') }}</h2>
            <p class="mt-5" v-html="$t('forage.text')"></p>
            <h3 class="slogan mt-10">{{ $t('forage.slogan') }}</h3>
            <ol style="padding: 0px 40px;">
              <li style="padding: 20px 0px;" v-html="$t('forage.text2')"></li>
              <li style="padding: 20px 0px;" v-html="$t('forage.text3')"></li>
            </ol>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--contrôleur à pression-->
    <section id="pressureController" class="gridpad mt-10">
      <v-row class=" d-flex flex-wrap-reverse justify-center">
        <v-col cols="12" lg="6">
          <div class="controllerPad">
            <h2 class="subtitle">{{ $t('controller.title') }}</h2>
            <p class="mt-5" v-html="$t('controller.txt1')"></p>
            <p v-html="$t('controller.txt2')"></p>
            <router-link class="button mt-4" to="/contact" ><p class="buttonText" v-html="$t('controller.btn')"></p></router-link>
          </div>
        </v-col>
        <v-col cols="12" lg="6" >
          <v-col cols="12" class="py-0 d-flex justify-center"><div class="controllerImg1"></div></v-col>
          <v-col cols="12" class=" hideMController"><div class="controllerImg2"></div></v-col>
        </v-col>
      </v-row>
    </section>

  </div>
</template>

<style scoped>
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .subtitle {line-height: 30px;}
  .txtAlign {text-align:center;}
  .gridpad {padding-bottom:50px;}
  .sub {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;text-transform: uppercase;}

  /*#region Banner*/ 
  .topBanner {height: 450px; background-image: url('../../public/Resources/images/Banner/bannerArtesian.jpg'); background-position: center; background-position-y: 100%; background-repeat: no-repeat; background-size: cover;}
  /*#endregion*/ 
  /*#region section Info*/ 
  .titleInfo {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; }
  .icon {width: 50px; height: 50px; margin-bottom: -10px;}
  /*#endregion*/ 
  /*#region Forage*/ 
  .bgForage {background-color: rgb(var(--tierce2)); padding: 50px 0px 50px 0px;}

  .imgForage {background-image: url("../../public/Resources/images/Services/summersiblePump.jpg"); height:100%; width: 100%; background-position: center center; background-repeat: no-repeat; background-size:contain;}
  .padForage {padding:15px 150px 0px 45px;}
  /*#endregion*/ 

  /*#region analysis*/ 
  .imgAnalysis {background-image: url("../../public/Resources/images/Services/wellsWaterDrink.jpg"); height:800px; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .padAnalis {padding:45px 45px 0px 150px;}
  /*#endregion*/
   /*#region repare Motor*/ 
   .imgmotor1 {background-image: url("../../public/Resources/images/Services/electricMotorRepair1.jpg"); height:100%; max-height:700px; min-height: 550px; width:100%; background-position: center; background-repeat: no-repeat; background-size:cover;}
   .imgmotor2 {background-image: url("../../public/Resources/images/Services/MoteurY559.jpg"); height:100%; max-height:700px; min-height: 550px; width:100%; background-position: center; background-repeat: no-repeat; background-size:cover;}
   /*#endregion*/ 
   /*#region mantenance and repair Images*/ 
   .imgReparePump1 {background-image: url("../../public/Resources/images/Services/moteurPompe.jpg"); height:100%; max-height:700px; min-height: 550px; width:100%; background-position: center; background-repeat: no-repeat; background-size:cover;}
   .imgReparePump2 {background-image: url("../../public/Resources/images/Services/MoteurPompeNeuf.jpg"); height:100%; max-height:700px; min-height: 550px; width:100%; background-position: center; background-repeat: no-repeat; background-size:cover;}
   /*#endregion*/ 
    /*#region controller*/ 
    .hideMController { padding-top: 32px ;}
  .controllerPad {text-align: justify; padding: 30px 0px 0px 0px}
  .controllerImg1 {
    
    height:300px; width:100%; 
    background-image: url("../../public/Resources/images/Products/AquavarSolo-images.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:100%;
  }
  .controllerImg2 {
    height:475px; 
    background-image: url("../../public/Resources/images/Products/AquavarSolo-GoudspumpsV2.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  /*#endregion*/ 

  @media handheld, only screen and (max-width: 600px) {
  }

  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 0px 25px 50px 25px;}
    .txtAlign {text-align:justify;}
  }

  @media handheld, only screen and (max-width: 1279px) {
  .imgForage { height:300px; background-position-y: 33%; background-repeat: no-repeat; background-size:100%;}
  .imgAnalysis { height:300px; background-position-y: 66%; background-repeat: no-repeat; background-size:150%;}
}
  @media handheld, only screen and (max-width: 1378px) {
    .padForage {padding:15px !important;}
    .padAnalis {padding:15px !important;}
  }
  @media handheld, only screen and (max-width: 1594px)/*Padding forage*/ {
    .padForage {padding:45px 15px 0px 15px;}
    .padAnalis {padding:45px 15px 0px 15px;}
  }
</style>

<script>
    import pageTranslations from '../../public/Resources/i18N/services.json';
    import distributors from '../components/distributors.vue';
    import services from '../components/services.vue';
    import contactUs from '@/components/bannerContactUs.vue'
  export default {
    name: 'artesianWells',
    data: () => ({}),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    components: {
      distributors,
      contactUs,
      services
    },
  }
</script>
  