<template>
  <div >

    <!--Banner-->
    <section>
      <div class="topBanner"></div>
    </section>

    <!--Intro-->
    <section class="gridpad">
      <v-row>
        <v-col cols="12" class=" ">
          <div class="txt-c mt-11">
            <h1 class="titleIntro gridpadSub">{{ $t('intro.title') }}</h1>
            <p class="inlineTxt" v-html="$t('intro.text')"></p>
          </div>
        </v-col>
      </v-row>
    </section>
        <!--dynablast-->
    <section id="dynablast" class="">
      <v-row class="d-flex justify-center dynablastPad">
        <v-col class="d-flex align-center justify-center minBoxImg">
          <div class="dynablastBox ">
            <h2 class="dynablastTitle">{{ $t('modelDynablast.title') }}</h2>
            <p class="mt-5" v-html="$t('modelDynablast.text')"></p>
            <ol style="padding: 20px 40px;">
              <li v-html="$t('modelDynablast.ol1')"></li>
              <li v-html="$t('modelDynablast.ol2')"></li>
              <li v-html="$t('modelDynablast.ol3')"></li>
              <li v-html="$t('modelDynablast.ol4')"></li>
              <li v-html="$t('modelDynablast.ol5')"></li>
              <li v-html="$t('modelDynablast.ol6')"></li>
              <li v-html="$t('modelDynablast.ol7')"></li>
            </ol>
            <p class="f-secondary" v-html="$t('modelDynablast.subtxt')"></p>
            <ul style="padding: 20px 40px;">
              <li v-html="$t('modelDynablast.ul1')"></li>
              <li v-html="$t('modelDynablast.ul2')"></li>
              <li v-html="$t('modelDynablast.ul3')"></li>
              <li v-html="$t('modelDynablast.ul4')"></li>
            </ul>
            <p class="f-secondary mt-5" v-html="$t('modelDynablast.bottomTxt')"></p>
          </div>
        </v-col>
        <v-col class="pa-0 d-flex align-center minBoxImg">
          <div class="dynablastImg"></div>
        </v-col>
      </v-row>
    </section>
    <!--Section4 ContactUs-->
    <contactUs/>
    <!--Model Kodiak 3250-->
    <section id="kodiak3250" class="v-tierce2">
      <v-row class="">
        <v-col class="d-flex align-center pa-0 minBoxImg">
          <div class="kodiak3250Img"></div>
        </v-col>
        <v-col class="d-flex align-center justify-center minBoxImg">
          <div class="kodiak3250Pad">
            <h2 class="kodiak3250Title">{{ $t('modelKodiak3250.title') }}</h2>
            <p class="mt-5" v-html="$t('modelKodiak3250.text')"></p>
            <ol style="padding: 20px 40px;">
              <li v-html="$t('modelKodiak3250.ol1')"></li>
              <li v-html="$t('modelKodiak3250.ol2')"></li>
              <li v-html="$t('modelKodiak3250.ol3')"></li>
              <li v-html="$t('modelKodiak3250.ol4')"></li>
              <li v-html="$t('modelKodiak3250.ol5')"></li>
              <li v-html="$t('modelKodiak3250.ol6')"></li>
              <li v-html="$t('modelKodiak3250.ol7')"></li>
            </ol>
            <p class="f-secondary" v-html="$t('modelKodiak3250.subtxt')"></p>
            <ul style="padding: 20px 40px;">
              <li v-html="$t('modelKodiak3250.ul1')"></li>
              <li v-html="$t('modelKodiak3250.ul2')"></li>
              <li v-html="$t('modelKodiak3250.ul3')"></li>
              <li v-html="$t('modelKodiak3250.ul4')"></li>
            </ul>
            <p class="f-secondary mt-5" v-html="$t('modelKodiak3250.bottomTxt')"></p>
          </div>
        </v-col>
      </v-row>
    </section>
    <!--Model Kodiak2100-->
    <section id="kodiak2100">
      <v-row class="d-flex justify-center">
        <v-col class="d-flex align-center justify-center minBoxImg">
          <div class="kodiak2100Pad">
            <h2 class="kodiak2100Title">{{ $t('modelKodiak2100.title') }}</h2>
            <p class="mt-5" v-html="$t('modelKodiak2100.text')"></p>
            <ol style="padding: 20px 40px;">
              <li v-html="$t('modelKodiak2100.ol1')"></li>
              <li v-html="$t('modelKodiak2100.ol2')"></li>
              <li v-html="$t('modelKodiak2100.ol3')"></li>
              <li v-html="$t('modelKodiak2100.ol4')"></li>
              <li v-html="$t('modelKodiak2100.ol5')"></li>
              <li v-html="$t('modelKodiak2100.ol6')"></li>
              <li v-html="$t('modelKodiak2100.ol7')"></li>
            </ol>
            <p class="f-secondary" v-html="$t('modelKodiak2100.subtxt')"></p>
            <ul style="padding: 20px 40px;">
              <li v-html="$t('modelKodiak2100.ul1')"></li>
              <li v-html="$t('modelKodiak2100.ul2')"></li>
              <li v-html="$t('modelKodiak2100.ul3')"></li>
              <li v-html="$t('modelKodiak2100.ul4')"></li>
            </ul>
            <p class="f-secondary mt-5" v-html="$t('modelKodiak2100.bottomTxt')"></p>
          </div>
        </v-col>
        <v-col class="pa-0 d-flex align-center minBoxImg">
          <div class="kodiak3250Img"></div>
        </v-col>
      </v-row>
    </section>
    <!--Section4 ContactUs-->
    <contactUs/>
    <!--easy clean-->
    <section id="easyClean" class="v-primary-light-1 gridpad ">
      <v-row class="d-flex justify-center">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div class="easyCleanBox">
            <h2 class="easyCleanTitle">{{ $t('easyClean.title') }}</h2>
            <p class="mt-5" v-html="$t('easyClean.text')"></p>
            <ol style="padding: 20px 40px;">
              <li v-html="$t('easyClean.ol1')"></li>
              <li v-html="$t('easyClean.ol2')"></li>
              <li v-html="$t('easyClean.ol3')"></li>
              <li v-html="$t('easyClean.ol4')"></li>
              <li v-html="$t('easyClean.ol5')"></li>
              <li v-html="$t('easyClean.ol6')"></li>
            </ol>
            <p class="f-secondary" v-html="$t('easyClean.subtxt')"></p>
            <ul style="padding: 20px 40px;">
              <li v-html="$t('easyClean.ul1')"></li>
              <li v-html="$t('easyClean.ul2')"></li>
              <li v-html="$t('easyClean.ul3')"></li>
              <li v-html="$t('easyClean.ul4')"></li>
              <li v-html="$t('easyClean.ul5')"></li>
            </ul>
            <p class="f-secondary mt-5" v-html="$t('easyClean.bottomTxt')"></p>
          </div>
        </v-col>
      </v-row>
      <v-row class="pb-16">
        <v-col cols="12" class="pa-0 d-flex justify-center">
          <v-col v-for="(item, i) in transfertPumps" :key="i" class="">
            <v-container class="pumpItem pa-">          
              <img style="border: 10px solid rgb(var(--secondary)); box-shadow: 0px 0px 10px black;" class="pumpImg" :src="item.img"/>
            </v-container>
          </v-col>
        </v-col>
      </v-row>
    </section>
    <!--accessoires-->
    <section class="">
      <v-row class="d-flex justify-center">
        <v-col cols="12" class="d-flex align-center justify-center">
          <div class="easyCleanBox">
            <h2 class="easyCleanTitle">{{ $t('Accessoires.title') }}</h2>
          </div>
        </v-col>
      </v-row>
      <accesories/>
    </section>
    
  </div>
</template>

<style scoped>
  .minBoxImg { min-width: 525px; }
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .subtitle {line-height: 30px;}
  .gridpad {padding-bottom: 50px;}
  .topBanner {height: 450px; background-image: url('../../../public/Resources/images/Banner/bannerHighPressureWasher.jpg'); background-position: center; background-position-y: 100%; background-repeat: no-repeat; background-size: cover;}

  /*#region Intro*/ 
  .titleIntro {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}
  /*#endregion*/ 
  /*#region Model*/ 
  .imgModel {background-image: url("../../../public/Resources/images/Products/pressureWasher2.jpg"); height:735px; background-position: center center; background-repeat: no-repeat; background-size:contain;}
  .padTextModel {padding:0px 0px 0px 0px; text-align: justify;}
  /*#endregion*/
    /*#region dynablast*/
  
    #dynablast ol li {padding: 10px 0px;}

    .dynablastImg {
      height:650px; width:100%;
      background-image: url("../../../public/Resources/images/Products/Dynablast.jpg");
      background-position: center center; 
      background-repeat: no-repeat; 
      background-size:contain;
    }
    .dynablastBox { padding: 0px 0px 32px 0px; }
    .dynablastPad {text-align: justify; padding: 15px 50px 15px 50px;}
    .dynablastTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
/*#endregion*/ 
    /*#region kodiak3250*/
  
    #kodiak3250 ol li {padding: 10px 0px;}

    .kodiak3250Img {
      height:650px; width:100%;
      background-image: url("../../../public/Resources/images/Products/KC3250.jpg");
      background-position: center center; 
      background-repeat: no-repeat; 
      background-size:contain;
    }
    .kodiak3250Box { padding: 0px 0px 32px 0px;  }
    .kodiak3250Pad {text-align: justify; padding: 15px 50px 15px 50px; }
    .kodiak3250Title {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px; text-align: right;}
/*#endregion*/ 
    /*#region Kodiak2100*/
  
    #kodiak2100 ol li {padding: 10px 0px;}

    .kodiak2100Img {
      height:650px; width:100%;
      background-image: url("../../../public/Resources/images/Products/KC2100.jpg");
      background-position: center center; 
      background-repeat: no-repeat; 
      background-size:contain;
    }
    .kodiak2100Box { padding: 0px 0px 32px 0px; }
    .kodiak2100Pad {text-align: justify; padding: 15px 50px 15px 50px; }
    .kodiak2100Title {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
/*#endregion*/   
    /*#region easyClean*/
  
    #easyClean ol li {padding: 10px 0px;}

    .easyCleanImg {
      height:650px; width:100%; 
      background-image: url("../../../public/Resources/images/Products/FindImg.png");
      background-position: center center; 
      background-repeat: no-repeat; 
      background-size:contain;}
    .easyCleanBox { padding: 0px 0px 32px 0px;}
    .easyCleanPad {text-align: justify; padding: 15px 50px 15px 50px;}
    .easyCleanTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px; text-align: center;}
/*#endregion*/ 
    /*#region otherPump*/ 

    .boxOtherImg {height: 390px; overflow: hidden;}
    .otherBox { padding: 44px 0px 32px 0px;}
    .otherPad {text-align: justify; padding: 0px 100px 0px 0px;}
    .otherTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
    .pumpItem {
      height: 170px;
      width: auto;
      max-width: 200px;
      position: relative; 
      display: block; 
      margin: auto auto !important;}
  .pumpImg {height: 250px;  width: auto; }
  /*#endregion*/ 

  @media handheld, only screen and (max-width: 600px) { 
    .minBoxImg { min-width: 100%; }
    .dynablastTitle { text-align: left; }
    .pumpItem { height: 200px; width: auto; max-width: unset; }
  }

  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 15px 15px 25px 15px;}
    .dynablastPad {padding: 10px;}
    .kodiak3250Pad {padding: 10px;}
    .kodiak2100Pad { padding: 10px; }    

  }
  @media handheld, only screen and (max-width: 1124px) {
    .kodiak3250Img { transform: unset; }
    .kodiak2100Img { transform: unset; }
  }
  

  @media handheld, only screen and (max-width: 1279px) {
    .imgModel {background-image: url("../../../public/Resources/images/Products/pressureWasher2.jpg"); max-height:367px; max-width:480px; margin:auto;}
    .padTextModel {padding:0px 5% 30px 5%}
  }
  @media handheld, only screen and (max-width: 1378px) {
  }
</style>

<script>
    import pageTranslations from '../../../public/Resources/i18N/highPressureWasher.json'
    import distributors from '../../components/distributors.vue';
    import contactUs from '@/components/bannerContactUs.vue';
    import accesories from '@/components/accesories.vue';

  export default {
    name: 'artesianWells',
    data: () => ({
      transfertPumps: [
        {
          img:'Resources/images/Products/QuakerBlast.jpg'
        }
      ],
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    components: {
      contactUs,
      accesories,
      distributors
    },
  }
</script>