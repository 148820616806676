<template>
  <div >
    <!--Banner-->
    <section>
      <div class="topBanner"></div>
    </section>

    <!--Intro-->
    <section>
      <v-row>
        <v-col cols="12">
          <div class="introBox">
            <img class="icon" src="/Resources/images/icon/reparation-moteur_hover.png"/>
            <h1 class="introTitle">{{ $t('intro.title') }}</h1>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-row-reverse">
        <v-col cols="12" lg="6"><div class="introImg1"></div></v-col>
        <v-col cols="12" lg="6">
          <p class="introText" v-html="$t('intro.txt')"></p>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="6" class="pa-0"><div class="introImg2"></div></v-col>
        <v-col cols="6" class="pa-0"><div class="introImg3"></div></v-col>
      </v-row>
    </section>

   <!--contactUs-->
   <contactUs/>

    <!--filters-->
    <section class="v-tierce2">
      <v-row class="ma-0 d-flex flex-md-row flex-column-reverse">
        <v-col cols="12" md="6">
          <v-row class="d-flex flex-lg-column">
            <v-col cols="6" md="12" class="pa-0"><div class="filtersImg1"></div></v-col>
            <v-col cols="6" md="12" class="pa-0"><div class="filtersImg2"></div></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <div class="filtersPad txt-j">
            <h2 class="filtersTitle">{{ $t('filters.title') }}</h2>
            <h3 class="subtitle">{{ $t('filters.subtitle') }}</h3>
            <p v-html="$t('filters.txt')"></p>
        </div>
      </v-col>
      </v-row>

    </section>
    
    <!--maintenanceProducts-->
    <section>
      <v-row class="ma-0">
        <v-col cols="12" md="6">
          <v-col cols="12">
            <div class="filtersPad txt-j">
              <h2 class="filtersTitle">{{ $t('maintenanceProducts.title') }}</h2>
              <h3 class="subtitle">{{ $t('maintenanceProducts.subtitle') }}</h3>
              <p v-html="$t('maintenanceProducts.txt')"></p>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex justify-center"><div class="saltImg"></div></v-col>
      </v-col>
        <v-col cols="12" md="6" class="">
          <v-row class="">
            <v-col cols="6" md="12" class="pa-0"><div class="mpImg1"></div></v-col>
            <v-col cols="6" md="12" class="pa-0"><div class="mpImg2"></div></v-col>
          </v-row>
        </v-col>
        
      </v-row>

    </section>

   <!--contactUs-->
   <contactUs/>

   <!--distribution-->
   <distributors/>

  </div>
</template>

<style scoped>
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .subtitle {line-height: 30px; font-size: 18px;}
  .gridpad {padding-bottom: 50px;}
  .gridpad2 {padding: 0px 100px 100px 100px;}
  .gridpad3 {padding: 0px 100px 0px 100px;}
  .gridpad4 {padding: 50px 100px 50px 100px;}
  .topBanner {height: 450px; 
    background-image: url('../../../public/Resources/images/Banner/bannerAccessories.jpg'); 
    background-position: center; background-position-y: 100%; 
    background-repeat: no-repeat; background-size: cover;
  }
  .icon {width: 50px; height: 50px; margin-bottom: -10px;}
  .txtAlign {text-align:center;}
  .uppercase {text-transform: uppercase;}
  .hideController { visibility: hidden; height:0px;}
  .hideMController { visibility: visible;}
  /*#region Intro*/ 
  .introBox {text-align: center; padding: 44px 150px 32px 150px;}
  .introText {text-align: justify; padding: 135px 150px 150px 150px;}
  .introTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase; padding-bottom:30px;}
  .introImg1 {
    height:550px; width:100%; 
    background-image: url("../../../public/Resources/images/Products/Piece-jointsmecaniqueHD.jpg");
    background-position: center; 

    background-repeat: no-repeat; 
    background-size:cover;
  }
  .introImg2 {
    height:500px; width:100%;
    background-image: url("../../../public/Resources/images/Products/Galerie-photo-produits-Amstrong.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .introImg3 {
    height:500px; width:100%;
    background-image: url("../../../public/Resources/images/Products/Piece-jointsmecaniqueV2-HD.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  /*#endregion*/ 

  /*#region Filters*/
  .filtersTitle {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;}
  .filtersPad {padding: 150px 100px 0px 100px;}
  .filtersImg1 {
    height:500px; width:100%;
    background-image: url("../../../public/Resources/images/Products/Filtre-texte_v2.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .filtersImg2 {
    height:500px; width:100%;
    background-image: url("../../../public/Resources/images/Products/Galerie-photo-produits-filtres.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  /*#endregion*/

  /*#region maintenanceProducts*/
  .saltImg {
    height:350px; width:350px;
    background-image: url("../../../public/Resources/images/Products/Sel-Produit-entretien-SacpoigneeV2.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .mpImg1 {
    height:500px; width:100%;
    background-image: url("../../../public/Resources/images/Products/Galerie-photo-produits.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  .mpImg2 {
    height:500px; width:100%;
    background-image: url("../../../public/Resources/images/Products/Galerie-photo-produits-Coroprotect.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:cover;
  }
  /*#endregion*/

  @media handheld, only screen and (max-width: 600px) {}

  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 0px 25px 50px 25px;}
    .gridpad2 {padding: 0px 25px 60px 25px;}
    .gridpad4 {padding: 0px 25px 60px 25px;}
    .gridpad3 {padding: 0px 25px 0px 25px;}
    .txtAlign {text-align:justify;}
    .filtersPad {padding: 0px 15px 0px 15px !important;}
    .filtersImg1 {
      height:200px; width:100%}
    .filtersImg2 {
      height:200px; width:100%}
    .mpImg1 {
      height:200px; width:100%}
    .mpImg2 {
      height:200px; width:100%}
  }

  @media handheld, only screen and (max-width: 1279px) {
    .introBox {padding: 44px 0px 0px 0px;}

    .filtersPad {padding: 150px 75px 0px 15px;}
    .introText {padding: 15px 15px 15px 15px !important;}
    .introImg1 {
      height:250px; width:100%}
    .introImg2 {
      height:200px; width:100%}
    .introImg3 {
      height:200px; width:100%}
  }
  @media handheld, only screen and (max-width: 1378px) {
    .introText {padding: 135px 75px 150px 75px;}
  }
</style>

<script>
    import pageTranslations from '../../../public/Resources/i18N/partsAndAccessories.json'
    import distributors from '../../components/distributors.vue'
    import contactUs from '../../components/bannerContactUs.vue';
  export default {
    name: 'artesianWells',
    data: () => ({}),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    components: {
      distributors,
      contactUs
    },
  }
</script>
  