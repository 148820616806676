<template>
  <div>
    <v-row>
            <v-col cols="12">
              <div class="boxContact">
                <div class="backgroundimg d-flex align-center justify-center">
                  <h1 class="f-tierce txtContact">Nos Produits</h1>
                </div>                
              </div>
            </v-col>
        </v-row>
    
    <section class="storeGridpad2 mt-10">
        <v-row class="ma-0 d-flex flex-sm-row flex-column-reverse">
            <v-col class=" d-flex justify-sm-start justify-center align-center pa-0"></v-col>
            <v-col class="d-flex justify-sm-end justify-center align-center searchCol pa-0">
                <v-text-field id="onlineStore_SearchInput" name="onlineStore_SearchInput" v-model="searchItem" class="searchBox" density="compact" variant="outlined" label="recherche" append-inner-icon="mdi-magnify" @click:append-inner="search()" @keydown.enter="search()"></v-text-field>
            </v-col>    
        </v-row>
    </section>
    <section class="container productContainer custom-scrollbar" style="overflow-x: hidden;">
        <v-row v-if="this.Products" class="scrollbar" >
            <v-col v-for="(item, i) in Products" :key="i">
                <v-container class="productBox"  @click="navigateTo(item)" >
                    <v-row class="ma-0 d-flex flex-column">
                        <v-col  class="pa-0" style="position: relative;">
                            <div class="productHover"> </div>
                            <div class="product"><img v-if="item.image.url" :src="item.image.url" :truesrc="item.image.url" style="height:100%; width:100%; "/></div>
                        </v-col>
                        <v-col cols="12">
                          <h3 class="txt-c bold uppercase f-primary" style="min-height: 50px;">{{item.title}}</h3>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row v-if="this.Products.length == 0 && loading == false"><h2 class="soustitre" v-html="$t('section.noProduct')"></h2></v-row>
        <v-row v-if="loading == true">
            <div style="width: 75%; display: block; margin-left: auto !important; margin-right: auto !important;">
                <h2 class="soustitre">Le chargement des produits est en cours d'exécution.</h2>
                <v-progress-linear color="rgb(var(--primary))" indeterminate striped height="8"></v-progress-linear>
            </div>
        </v-row>
    </section>
    <v-dialog class="" style="z-index: 10000; object-position: center center;" v-model="showSelectedProduct">
        <template v-slot:default="{ isActive }">
          <v-card class="custom-scrollbar">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text="X"
                @click="isActive.value = false"
              ><span class="bold" style="font-size: 22px;">X</span></v-btn>
            </v-card-actions>
            <v-card-text>
              <v-row class="">
                
                <v-col class="d-flex align-center justify-center">
                  <div class="borderPrimary" style="max-height:100%; max-width:100%;">
                    <img :src="productSelected.image.url" :truesrc="productSelected.image.url" style="height:100%; max-height: 800px; width:100%"/>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
    </v-dialog>

  </div>
</template>

<style scoped>
    .storeGridpad {padding: 25px 25px 0px 25px;}
    .storeGridpad2 {padding: 0px 25px 0px 25px;}
    .searchBox {color: rgb(var(--secondary)); font-weight: 700; max-width:400px; min-width: 200px;}
    .searchCol {min-width:200px; float: left;}
    .product {height: 200px;}
    .product img { object-fit: contain;}
    .productContainer {max-height: 1000px; min-height: 500px; overflow-y: scroll; margin: 50px;}
    .productContainer::-webkit-scrollbar { width: 1px !important;}
    .productBox {height: 100%; width: 100%; min-width: 200px; max-width: 250px; padding: 0px; cursor: pointer;}
    .productHover{position: absolute; background-color: rgba(var(--primary), 0.6); width: 100%; height: 100%; visibility: hidden; z-index: 100;}
    .textProductHover{position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 100%; color: white;}
    .productBox:hover .productHover{visibility: visible;}
    .productContainer::-webkit-scrollbar { width: 1px !important;}
    
    .custom-scrollbar { max-height: auto; overflow-y: scroll; scrollbar-width: thin; scrollbar-color: rgb(var(--primary)) rgba(var(--secondary),0.1); }
    .custom-scrollbar::-webkit-scrollbar-thumb { background-color: #000; border-radius: 5px; }
    .custom-scrollbar::-webkit-scrollbar-track { background-color: #f1f1f1; }
    .boxContact {
      position:relative; height:115px; width:100%;
      text-align: center;   
      background-image: url('../../public/Resources/images/General/ricardo-gomez-angel-8XDfQmFImtQ-unsplash.jpg');
      background-position: center; 
      background-repeat: no-repeat; 
      background-size: 100%;
    }
  .backgroundimg {
    position: absolute; height:115px; width:100%;
    
    background-color: rgba(var(--secondary),0.6);
  }
  .txtContact {font-size: 26px; font-weight: 700; ;}

  @media handheld, only screen and (max-width: 600px) {}

  @media handheld, only screen and (max-width: 960px) {
    .productContainer {margin: 20px;}
  }

  @media handheld, only screen and (max-width: 1279px) {}
  @media handheld, only screen and (max-width: 1378px) {

  }

</style>

<script>
  import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'
  
    export default {
    name: 'Produits',
    mixins: [apiECommerceExtension],
    data: () => ({
      Products: [],
      searchBox: false,
      searchItem: "",
      loading: false,
      range: null,
      response: null,
      showSelectedProduct: false,
      productSelected: null,
    }),
    mounted: async function() {
      this.loading = true;
      this.Products = await this.EcommerceProductsList(this.range,this.callback);
      if(this.Products){ this.loading = false; } 
    },
    methods:{
      changeSearchBox: function(){ if (!this.searchBox) {this.searchBox = true; }else{this.searchBox = false;} },
      navigateTo: function (item) {this.$router.push('/produit/' + item.id);},
      callback: function (response) {
        this.response = response;
        this.range = $App.getRangeHeader(response);
      },
      more: async function () {
        this.loading = true; this.Products = await this.EcommerceProductsList(this.range,this.callback); this.loading = false;    
      },
      selectedProduct: function(item) {
        this.productSelected = item;
        this.showSelectedProduct = true;
      },
      search: async function() {
        let filter = {"Keywords": this.searchItem};
        this.loading = true;
        this.Products = [];
        this.Products = await this.EcommerceProductsListWithFilter(filter,this.range,this.callback);
        if(this.Products) {this.loading = false;} 
      }
    },
    components: {
    },
  }
</script>
