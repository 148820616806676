<template>
  <div >

    <!--Section1 Banner-->
    <section>
      <div class="topBanner"></div>
    </section>

    <!--Section2 Info-->
    <section class="gridpad">
      <v-row>
        <v-col cols="12" class=" ">
          <div class="txt-c mt-11">
            <img class="icon" src="/Resources/images/icon/reparation-moteur-electriqueV2.png"/>
            <h2 class="subtitle gridpadSub">{{ $t('section.title') }}</h2>
            <p class="infoTextPad" v-html="$t('section.text')"></p>
          </div>
        </v-col>
      </v-row>
    </section>

    <!--Section4 ContactUs-->
    <contactUs/>

    <!--Section3 Images-->
    <section>
      <v-row class="d-flex justify-center">
        <v-col cols="6" class="pa-0">
          <div class="img1"></div>
        </v-col>
        <v-col cols="6" class="pa-0">
          <div class="img2"></div>
        </v-col>
      </v-row>
    </section>

    <!--section6 Distribution-->
    <distributors/>
  </div>
</template>

<style scoped>
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .subtitle {line-height: 30px;}
  .gridpad {padding-bottom: 50px;}
  /*#region Banner*/ 
  .topBanner {height: 450px; background-image: url('../../../public/Resources/images/Banner/bannerElectricMotorsRepair.jpg'); background-position: center; background-position-y: 100%; background-repeat: no-repeat; background-size: cover;}
  /*#endregion*/ 
  /*#region Info*/ 
  .icon {width: 50px; height: 50px; margin-bottom: -10px;}
  .infoTextPad {padding:0px 60px 0px 60px;}
  .title {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; text-transform: uppercase;}
  .gridpadSub {padding:0px 300px 30px 300px;}
 
   /*#endregion*/ 
   /*#region Images*/ 
   .img1 {background-image: url("../../../public/Resources/images/Services/electricMotorRepair1.jpg"); height:100%; max-height:700px; min-height: 550px; width:100%; background-position: center; background-repeat: no-repeat; background-size:cover;}
   .img2 {background-image: url("../../../public/Resources/images/Services/MoteurY559.jpg"); height:100%; max-height:700px; min-height: 550px; width:100%; background-position: center; background-repeat: no-repeat; background-size:cover;}
   /*#endregion*/ 

  @media handheld, only screen and (max-width: 600px) {
  }

  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 0px 25px 50px 25px;}
    .img1 { height:100%; max-height:700px; min-height: 250px;}
    .img2 { height:100%; max-height:700px; min-height: 250px;}
    .infoTextPad {padding:0px 0px 0px 0px; text-align: justify;}
  }

  @media handheld, only screen and (max-width: 1279px) {
  .subtitle {line-height: 30px; font-size: 22px;}
  }
  @media handheld, only screen and (max-width: 1378px) {
    .gridpadSub {padding:0px 25px 30px 25px;}
  }
</style>

<script>
    import pageTranslations from '../../../public/Resources/i18N/ElectricMotorRepair.json'
    import contactUs from '@/components/bannerContactUs.vue'
    import distributors from '@/components/distributors.vue';

  export default {
    name: 'ElectricMotorRepair',
    data: () => ({}),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    components: {
      contactUs,
      distributors
    },
  }
</script>