import { createRouter, createWebHistory } from 'vue-router'
//First Root
import HomeView from '../views/Home.vue'
import ContactUs from '../views/ContactUs.vue'
import experts from '../views/experts.vue'
import waterAnalysis from '../views/waterAnalysis.vue'
import waterPump from '../views/waterPump.vue'
import services from '../views/services.vue'
import armstrong from '../views/Armstrong.vue'
import products from '../views/products.vue'
import productDetailed from '../views/productDetailed.vue'
import Policy from '../views/Policy.vue'

//Product Root
import agriculturalCommercialAndIndustrialPumps from '../views/Products/agriculturalCommercialAndIndustrialPumps.vue'
import electricMotors from '../views/Products/electricMotors.vue'
import highPressureWasher from '../views/Products/highPressureWasher.vue'
import partsAndAccessories from '../views/Products/partsAndAccessories.vue'
import residentialWaterPump from '../views/Products/residentialWaterPump.vue'
import waterFilterAndTreatment from '../views/Products/waterFilterAndTreatment.vue'

//Services Root
import artesianWells from '../views/Services/artesianWells.vue'
import ElectricMotorRepair from '../views/Services/ElectricMotorRepair.vue'
import poolAndSpaPumpRepair from '../views/Services/poolAndSpaPumpRepair.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
  {
    path: '/contact',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/experts',
    name: 'experts',
    component: experts
  },
  {
    path: '/analyseEau',
    name: 'waterAnalysis',
    component: waterAnalysis
  },
  {
    path: '/Pompes',
    name: 'waterPump',
    component: waterPump
  },
  {
    path: '/services',
    name: 'services',
    component: services
  },
  {
    path: '/armstrong',
    name: 'armstrong',
    component: armstrong
  },
  {
    path: '/produits',
    name: 'products',
    component: products
  },
  {
    path: '/produit/:id',
    name: 'productDetailed',
    component: productDetailed
  },
  {
    path: '/PolitiqueDeConfidentialiter',
    name: 'Policy',
    component: Policy
  },
  //product route
  {
    path: '/pompes_agricoles_commerciales_industrielles',
    name: 'agriculturalCommercialAndIndustrialPumps',
    component: agriculturalCommercialAndIndustrialPumps
  },
  {
    path: '/moteurs_electriques',
    name: 'electricMotors',
    component: electricMotors
  },
  {
    path: '/laveuse_haute_pression',
    name: 'highPressureWasher',
    component: highPressureWasher
  },
  {
    path: '/pieces_et_accessoires',
    name: 'partsAndAccessories',
    component: partsAndAccessories
  },
  {
    path: '/pompe_a_eau_residentielle',
    name: 'residentialWaterPump',
    component: residentialWaterPump
  },
  {
    path: '/filtre_a_eau',
    name: 'waterFilterAndTreatment',
    component: waterFilterAndTreatment
  },
    //service route
  {
    path: '/puits_artesiens',
    name: 'artesianWells',
    component: artesianWells
  },
  {
    path: '/reparation_moteurs_electriques',
    name: 'ElectricMotorRepair',
    component: ElectricMotorRepair
  },
  {
    path: '/reparation_pompe_de_piscine',
    name: 'poolAndSpaPumpRepair',
    component: poolAndSpaPumpRepair
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      setTimeout(() => {
        if (el.length) {
          const offset = 150; // Décalage en pixels, ajustez selon vos besoins
          const elPosition = document.getElementById(el).getBoundingClientRect().top + window.pageYOffset;
          const scrollToPosition = elPosition - offset;
          window.scrollTo({
            top: scrollToPosition,
            behavior: "smooth"
          });
        }
      }, 5);
      
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  },
})

export default router
