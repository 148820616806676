<script setup>

</script>
<template>
    <main class="footer">
        <v-row class="d-flex flex-sm-row flex-column-reverse">
            <v-col cols="12" sm="5" class="d-flex align-center  justify-start">
                <p class="f-tierce  txtAlign">
                    {{ $t('footer.address') }} <br>
                    <a class="cookieLink" href="tel:819-472-7904" v-html="$t('footer.phone')"></a><br>
                    <a class="cookieLink" href="mailto:info@bissonservice.com" v-html="$t('footer.email')"></a><br>
                    {{ $t('footer.Licence') }}<br>
                    <span @click="openCookie()" class="cookieLink">Mes témoins</span><br>
                    <span @click="navigateToPolicy()" class="cookieLink">Politique de confidentialité</span>
                </p>
            </v-col>
            <v-col cols="12" sm="2" class="d-flex align-center justify-center">
                <img class="logo" style="" src="/Resources/images/logo/logoFooter.png" />
            </v-col>
            <v-col cols="12" sm="5" class="d-flex align-center  justify-sm-end justify-start">
                <p class="f-tierce ">
                    {{ $t('footer.BusinessHours') }} <br>
                    {{ $t('footer.mondayToFriday') }}<br>
                    {{ $t('footer.saturday') }}<br>
                    {{ $t('footer.sunday') }}<br><br>
                    {{ $t('footer.emergencyCall') }}
                    <a class="cookieLink" href="tel:819-472-7904" v-html="$t('footer.phone')"></a>
                </p>
            </v-col>
        </v-row>

    </main>
    
</template>
<style scoped>
    .footer {padding: 100px;}
    .v-col {padding: 0px;}
    .logo {height: 100px; width: auto;}
    .txtAlign {text-align: right;}
    .cookieLink { color: rgb(var(--tierce)) ; text-decoration: none ; cursor: pointer; display: inline-block;}
    .cookieLink:hover { text-decoration: underline !important; }

    @media handheld, only screen and (max-width: 600px) {
        .footer {padding: 20px!important;}
  }

  @media handheld, only screen and (max-width: 960px) {

    .logo {height: 0px; width: auto;}
    
    .txtAlign {text-align: left;}
  }

  @media handheld, only screen and (max-width: 1279px) {
  .footer {padding: 30px;}

  }
  @media handheld, only screen and (max-width: 1378px) {

  }
</style>
<script>
    import translations from '../../public/Resources/i18N/global.json';
export default {
    name: 'Footer',
    data: () => ({}),
    mounted() {
        this.$i18n.mergeLocaleMessage('fr', translations.fr);
        this.$i18n.mergeLocaleMessage('en', translations.en);
    },
    methods: {
        navigateToPolicy: function(){ this.$router.push('/PolitiqueDeConfidentialiter'); },
        openCookie: function(){ $App.event.$emit('openCookie'); }
    },
}
</script>