<template>
  <div >

    <!--Banner-->
    <section>
      <div class="topBanner"></div>
    </section>
    
    <!--Intro-->
     <section class="gridpad">
      <v-row>
        <v-col cols="12" class=" ">
          <div class="txt-c mt-11">
            <img class="icon" src="/Resources/images/icon/reparation-moteur-electriqueV2.png"/>
            <h1 class="titleInfo gridpadSub">{{ $t('intro.title') }}</h1>
            <p class="txtAlign" v-html="$t('intro.text')"></p>
          </div>
        </v-col>
      </v-row>
    </section>

    <!--Forage-->
    <section class="bgForage">
      <v-row class="d-flex justify-center">
        <v-col cols="12"  lg="6" class="pa-0">
          <div class="imgForage"></div>
        </v-col>
        <v-col cols="12" lg="6" class="txt-j">
          <div class="padForage">
            <h2 class="sub">{{ $t('forage.title') }}</h2>
            <h3 class="slogan">{{ $t('forage.slogan') }}</h3>
            <p v-html="$t('forage.text')"></p>
            <v-col><a href="https://www.lalimeetfreres.com/" target="blank"><img src="/Resources/images/Products/header-Logo-Lalime.png" /></a></v-col>
          </div>
        </v-col>
      </v-row>
    </section>

    <!--ContactUs-->
    <contactUs/>

    <!--analysis-->
    <section>
      <v-row class="d-flex flex-wrap-reverse justify-center">
        <v-col cols="12" lg="6" class="txt-j">
          <div class="padAnalis">
            <h2 class="sub">{{ $t('analysis.title') }}</h2>
            <h3 class="slogan">{{ $t('analysis.slogan') }}</h3>
            <p v-html="$t('analysis.textPhys')"></p>
            <p v-html="$t('analysis.textProc')"></p>
            <p v-html="$t('analysis.textBact')"></p>
            <router-link class="button mt-5" to="/contact" ><p class="buttonText" v-html="$t('analysis.contactUs')"></p></router-link>
          </div>
        </v-col>
        <v-col cols="12"  lg="6" class="pa-0">
          <div class="imgAnalysis"></div>
        </v-col>
      </v-row>
    </section>

     <!--Distribution-->
     <distributors/>

  </div>
</template>

<style scoped>
  p {font-size: 18px; color: rgba(var(--menuColor),0.6);}
  .subtitle {line-height: 30px;}
  .txtAlign {text-align:center;}
  .gridpad {padding-bottom:50px;}
  .sub {color: rgb(var(--secondary)); font-weight: 700; font-size: 26px;text-transform: uppercase;}

  /*#region Banner*/ 
  .topBanner {height: 450px; background-image: url('../../../public/Resources/images/Banner/bannerArtesian.jpg'); background-position: center; background-position-y: 100%; background-repeat: no-repeat; background-size: cover;}
  /*#endregion*/ 
  /*#region section Info*/ 
  .titleInfo {color: rgb(var(--secondary)); font-weight: 700; font-size: 30px; }
  .icon {width: 50px; height: 50px; margin-bottom: -10px;}
  /*#endregion*/ 
  /*#region Forage*/ 
  .bgForage {background-color: rgb(var(--tierce2)); padding: 50px 0px 50px 0px;}

  .imgForage {background-image: url("../../../public/Resources/images/Services/summersiblePump.jpg"); height:700px; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .padForage {padding:15px 150px 0px 45px;}
  /*#endregion*/ 

  /*#region analysis*/ 
  .imgAnalysis {background-image: url("../../../public/Resources/images/Services/wellsWaterDrink.jpg"); height:800px; background-position: center; background-repeat: no-repeat; background-size:cover;}
  .padAnalis {padding:45px 45px 0px 150px;}
  /*#endregion*/ 

  @media handheld, only screen and (max-width: 600px) {
  }

  @media handheld, only screen and (max-width: 960px) {
    .gridpad {padding: 0px 25px 50px 25px;}
    .txtAlign {text-align:justify;}
  }

  @media handheld, only screen and (max-width: 1279px) {
  .imgForage { height:300px; background-position-y: 33%; background-repeat: no-repeat; background-size:100%;}
  .imgAnalysis { height:300px; background-position-y: 66%; background-repeat: no-repeat; background-size:150%;}
}
  @media handheld, only screen and (max-width: 1378px) {
    .padForage {padding:15px !important;}
    .padAnalis {padding:15px !important;}
  }
  @media handheld, only screen and (max-width: 1594px)/*Padding forage*/ {
    .padForage {padding:45px 15px 0px 15px;}
    .padAnalis {padding:45px 15px 0px 15px;}
  }
</style>

<script>
    import pageTranslations from '../../../public/Resources/i18N/artesianWells.json';
    import distributors from '../../components/distributors.vue';
    import contactUs from '@/components/bannerContactUs.vue'
  export default {
    name: 'artesianWells',
    data: () => ({}),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    components: {
      distributors,
      contactUs,
    },
  }
</script>
  